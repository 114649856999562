import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId,
} from '../fetch';
import { RolePathType } from '../../utils';
import { Order } from './order';

export enum OfferStatus {
  'pending' = 'Pending',
  'declined' = 'Declined',
  'accepted' = 'Accepted',
}

export interface Offer extends OfferAll {
    id: string;
    createdAt: string;
  }

  interface OffersAllGetParams extends PagingParams {
    orderByColumn?: 'name';
  }

export const useOffersGet = <DD = OffersTableData>(
  decorateData?: (data: PagingDataResponse<Offer>) => DD,
): FetchGet<
    PagingDataResponse<Offer>,
    OffersAllGetParams,
    DefaultFetchError,
    DD
  > => useFetchGet(
    'admin/offers',
    { autoStart: false, startStateLoading: false, decorateData },
  );

export interface OfferAll {
  number: string,
  dueDate: string,
  description: string,
  currency: string,
  conversionRate: number,
  offerStatus: string,
  order: Order,
  services: ServicesDetails[],
  roundDifference: number,
}

interface ServicesDetails {
  id: string;
  name: string;
  value: number;
  valueCHF: number;
  tax: number;
  quantity: number;
  units: string;
  kind: string;
}

export interface OffersTable extends Offer {
  key: string;
}

export interface OffersTableData {
  data: OffersTable[];
  total: number;
}

export const useOffersTableGet = () => useOffersGet<OffersTableData>(
  ({ data, meta }: PagingDataResponse<Offer>): OffersTableData => ({
    data: data.map((item: Offer): OffersTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

// export const useOffersOptionsGet = () => useOffersGet<Option[]>(
//   ({ data }: PagingDataResponse<Offers>): Option[] => (
//     data.map((item) => (
//       { value: item?.status || '', label: item?.status || '' }
//     ))
//   ),
// );

export const useOffersGetById = <DD = Offer>(
  rolePath: RolePathType,
  id?: string,
  decorateData?: (data: Offer) => DD,
): FetchGetId<
  Offer,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    `${rolePath}/offers`,
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export const useOffersDelete = (rolePath: RolePathType, id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete(`${rolePath}/offers`, id)
);

export interface OfferCreateParams {
  id: string,
}

export const useOfferResend = (
  rolePath: RolePathType,
  id?: string,
): FetchCreate<OfferCreateParams, DefaultFetchError> => (
  useFetchCreate(`${rolePath}/offers/${id}/send-by-mail`)
);

export const useOfferDownload = (
  rolePath: RolePathType,
  id: string,
  abortController?: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    `${rolePath}/offers/${id}/download-as-pdf`,
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController?.signal,
      },
    },
    'arraybuffer',
  )
);
