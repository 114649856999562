import React from 'react';
import { Divider } from 'antd';
import { OrderStatus } from '../../../Adapter/enums';
import { ClientData } from '../../../Adapter';
import { Invoice, ServiceKindType } from '../../../../../../hooks/api/invoices';

import styles from './index.module.scss';

export const calculateInvoiceTotals = (invoices: Invoice[]) => {
  let totalTax = 0;
  let totalValue = 0;
  const invoicesCurrency = invoices?.[0]?.currency || 'CHF';

  /** Use an object to accumulate services by their 'kind' */
  const servicesMap: { [kind: string]: { name: string; value: number; kind: ServiceKindType } } = {};

  (invoices || []).forEach((invoice) => {
    (invoice.services || []).forEach((service) => {
      const serviceTotal = service.value * service.quantity;
      const serviceTax = (serviceTotal * service.tax) / 100;

      /** Round to 2 decimal places for CHF currency */
      const roundedServiceTotal = Math.round(serviceTotal * 100) / 100;
      const roundedServiceTax = Math.round(serviceTax * 100) / 100;

      /** Accumulate total tax and total order value */
      totalTax += roundedServiceTax;
      totalValue += roundedServiceTotal + roundedServiceTax;

      /** If the service kind already exists in the map, sum the values */
      if (servicesMap[service.kind]) {
        servicesMap[service.kind].value += roundedServiceTotal;
      } else {
        /** Otherwise, add it to the map */
        servicesMap[service.kind] = {
          name: service.name,
          value: roundedServiceTotal,
          kind: service.kind,
        };
      }
      /*
      servicesSummary.push({
        name: service.name,
        value: roundedServiceTotal,
        kind: service.kind,
      }); */
    });
  });

  /** Convert the servicesMap into an array */
  const servicesSummary = Object.values(servicesMap);

  /** Round final total tax to 2 decimal places */
  totalTax = Math.round(totalTax * 100) / 100;
  totalValue = Math.round(totalValue * 100) / 100;

  return {
    servicesSummary, totalTax, totalValue, invoicesCurrency,
  };
};

interface SummaryBox {
  clientOrderData: ClientData | null;
  isAssistanceNeeded: boolean;
  style?: React.CSSProperties;
  className?: string;
}

function SummaryBox({ clientOrderData, isAssistanceNeeded, ...rest }: SummaryBox) {
  const {
    servicesSummary, totalTax, totalValue, invoicesCurrency,
  } = calculateInvoiceTotals(clientOrderData?.invoices || []); // order.data?.invoices

  return (
    <div className={styles.summaryBox} {...rest}>
      <div className={styles.title}>Summary</div>
      {/** If assistance needed and order still not processed by admin - dont show summary. */}
      {(isAssistanceNeeded && clientOrderData?.status && [
        OrderStatus.DRAFT,
        OrderStatus.PENDING,
        OrderStatus.IN_PROGRESS,
      ].includes(clientOrderData?.status))
      || !clientOrderData?.invoices?.length ? (
        <p className="text-secondary">
          The list of services and their cost will be determined after considering your request
        </p>
        ) : null}

      {/** If there's no assistance needed - or order already processed by admin - show prices */}
      {clientOrderData?.invoices?.length && (!isAssistanceNeeded || (clientOrderData?.status && ![
        OrderStatus.DRAFT,
        OrderStatus.PENDING,
        OrderStatus.IN_PROGRESS,
      ].includes(clientOrderData.status))) ? (
        <>
          {(servicesSummary || []).map((item) => (
            <div className={styles.item} key={item.name}>
              <span title={item.name} className="truncate">
                {item.name}
              </span>
              <span style={{ whiteSpace: 'nowrap' }}>
                {item.value?.toFixed(2)}
                {' '}
                {invoicesCurrency}
              </span>
            </div>
          ))}
          {totalTax ? (
            <div className={styles.item}>
              <span>Taxes</span>
              <span>
                {totalTax?.toFixed(2)}
                {' '}
                {invoicesCurrency}
              </span>
            </div>
          ) : null}
          <Divider style={{ margin: '16px 0' }} />

          <div className={styles.total}>
            <span>Order total</span>
            <span>
              {totalValue?.toFixed(2)}
              {' '}
              {invoicesCurrency}
            </span>
          </div>
        </>
        ) : null}
    </div>
  );
}

export default SummaryBox;
