import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import { Tag } from 'antd';
import { ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import { ProColumns } from '@ant-design/pro-table/es/typing';
import dayjs from 'dayjs';
import {
  downloadFromAnchor, getSorterParams, getUserRequestPath, queryFilterParams,
} from '../../../utils';
import Table, { listAction } from '../../Common/Table';

import { useSimpleModal } from '../../Common/Modal/Simple';
import { useAuth } from '../../../store/auth';
import { useMessageError, useMessageSuccess } from '../../../hooks/common';
import {
  InvoicesTable, InvoiceStatus, useInvoiceDownload, useInvoicesDelete, useInvoicesTableGet,
} from '../../../hooks/api/invoices';
import InvoicesModal from './View';
import { isRoleEnough } from '../../../enums/user';

export enum InvoiceStatusColor {
  'invisible' = 'default',
  'draft' = 'default',
  'expired' = 'magenta',
  'pending' = 'gold',
  'overdue' = 'red',
  'paid' = 'green',
}

export function getInvoiceStatusColor(status: string): string {
  // @ts-ignore take a color from the list
  return InvoiceStatusColor[status?.toLowerCase()] || 'rgba(0,0,0,.15)';
}

function DeleteButton({ record, setTableKey }: { record: InvoicesTable } & AnyObject) {
  const { user } = useAuth();
  const invoiceDelete = useInvoicesDelete(getUserRequestPath(user?.role));
  const { open, contextHolder } = useSimpleModal();

  useMessageError([invoiceDelete]);
  useMessageSuccess([invoiceDelete], 'Invoice deleted successfully');

  useEffect(() => {
    if (!invoiceDelete.error && !invoiceDelete.loading && invoiceDelete.data) {
      setTableKey(Date.now());
    }
  }, [invoiceDelete.error, invoiceDelete.loading, invoiceDelete.data]);

  const ActionButton = listAction.delete;

  if (user?.id === record.id) {
    return null;
  }
  if (isRoleEnough(user?.role, 'admin')) {
    return (
      <>
        {record.status !== 'paid'
          ? (
            <ActionButton
              loading={invoiceDelete.loading}
              title="Delete invoice"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                open({
                  icon: <ExclamationCircleFilled />,
                  title: 'Delete invoice?',
                  content: (
                    <span>
                      Are you sure you want to delete invoice
                      {' '}
                      <b>{record.number}</b>
                      ?
                    </span>
                  ),
                  cancelText: 'Cancel',
                  okText: 'Delete',
                  okButtonProps: {
                    danger: true,
                  },
                  onOk: () => invoiceDelete.fetch(record.id),
                });
              }}
            />
          )
          : null}
        {contextHolder}
      </>
    );
  }
}
function DownloadButton({ record }: { record: InvoicesTable } & AnyObject) {
  const { user } = useAuth();
  const { contextHolder } = useSimpleModal();
  const downloadInvoice = useInvoiceDownload(getUserRequestPath(user?.role), record.id);

  useMessageError([downloadInvoice]);
  useMessageSuccess([downloadInvoice], 'Download successfully');

  const ActionButton = listAction.download;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <>
      <ActionButton
        loading={downloadInvoice.loading}
        title="Download invoice"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          downloadInvoice.fetch()
            .then((response) => {
              if (!response) return;

              downloadFromAnchor(response, `invoice_${record.number}`, 'application/pdf');
            });
        }}
      />
      {contextHolder}
    </>
  );
}
function getDaysAfterDueDate(date: string): number {
  const dueDate = dayjs(date);
  const currentDate = dayjs();

  return currentDate.diff(dueDate, 'day');
}

export default function InvoicesContent({ companyId }: { companyId?: string }): React.ReactNode | null {
  const { user } = useAuth();
  const invoicesTableGet = useInvoicesTableGet(getUserRequestPath(user?.role));
  const [, setSearchParams] = useSearchParams();
  const [tableKey, setTableKey] = useState<number>(Date.now());
  const [isModalOpen, setModalOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');
  const handleClick = (id: string) => {
    setInvoiceId(id);
    setModalOpen(true);
  };

  const columns: ProColumns<InvoicesTable>[] = [
    {
      title: 'Invoice No.',
      dataIndex: 'number',
      key: 'number',
      sorter: true,
      hideInSearch: true,
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {text}
          {record.status === 'overdue' ? (
            <WarningOutlined className="warning-icon" />
          ) : null}
        </div>
      ),
    },
    {
      title: 'Order No.',
      dataIndex: 'order',
      key: 'order',
      hideInSearch: true,
      render: (text, record) => (
        <NavLink
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          to={`/orders/${record?.order?.id}`}
        >
          {record?.order?.orderNumber}
        </NavLink>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      hideInSearch: true,
      render: (text, record) => (`${record.amount} ${record.currency}`),
    },
    {
      title: 'Date added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      hideInSearch: true,
      sorter: true,
      render: (text, record) => (
        dayjs(record.createdAt).format('DD/MM/YYYY')
      ),
    },
    {
      title: 'Due date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      hideInSearch: true,
      sorter: true,
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{dayjs(record.dueDate).format('DD/MM/YYYY')}</span>
          {record.status === 'overdue' ? (
            <span style={{ color: '#FF4D4F' }}>{`${getDaysAfterDueDate(record.dueDate)} days`}</span>
          ) : null}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      valueType: 'select',
      valueEnum: InvoiceStatus,
      render: (_, record) => (
        <Tag color={getInvoiceStatusColor(record.status)}>{record.status}</Tag>
      ),
    },
    {
      title: 'Date added',
      dataIndex: 'createdAt',
      valueType: 'dateRange',
      key: 'createdAt',
      hideInTable: true,
      search: {
        transform: (value) => (value ? ({
          dateAddedFrom: value[0],
          dateAddedTo: value[1],
        }) : undefined),
      },
    },
  ];

  const tableRequest = useCallback(async (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<InvoicesTable>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    if (companyId) {
      Object.assign(newParams, {
        companyId,
      });
    }

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    const response = await invoicesTableGet.fetch({
      ...newParams,
    });

    if (response) {
      const { data, total } = response;

      return ({
        data: (data || []).map(((item) => ({
          ...item,
          key: item.id,
        }))),
        success: true,
        total,
      });
    }

    return ({ data: [], success: false, total: 0 });
  }, []);

  useMessageError([invoicesTableGet]);

  return (
    <>
      <Table<InvoicesTable>
        key={tableKey}
        className="transparent"
        rowClassName="cursor-pointer"
        headerTitle="List of invoices"
        onRow={(record) => ({
          onClick: (event) => {
            event.preventDefault();
            handleClick(record.id);
          },
        })}
        request={tableRequest}
        columns={columns}
        actions={[
          [DownloadButton, {
            setTableKey,
          }],
          [DeleteButton, {
            setTableKey,
          }],
        ]}
        search={{
          searchText: 'Filter',
          resetText: 'Clear',
        }}
        options={{
          search: {
            name: 'search',
          },
        }}
        columnsState={{ persistenceKey: 'pro-table-invoices', persistenceType: 'localStorage' }}
      />
      <InvoicesModal
        showFooter={false}
        id={invoiceId}
        isModalOpen={isModalOpen}
        handleClose={() => setModalOpen(false)}
        setTableKey={setTableKey}
      />
    </>
  );
}
