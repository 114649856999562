import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId,
} from '../fetch';
import { Option } from '../../types';
import { RolePathType } from '../../utils';
import { Order } from './order';

export enum InvoiceStatus {
  'pending' = 'Pending',
  'overdue' = 'Overdue',
  'paid' = 'Paid',
}

export interface Invoice extends InvoiceAll {
  id: string;
  createdAt: string;
  updatedAt: string;
}

interface InvoicesGetParams extends PagingParams {
  orderByColumn?: 'name';
}

export const useInvoicesGet = <DD = InvoicesTableData>(
  rolePath: RolePathType,
  decorateData?: (data: PagingDataResponse<Invoice>) => DD,
): FetchGet<
  PagingDataResponse<Invoice>,
  InvoicesGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    `${rolePath}/invoices`,
    { autoStart: false, startStateLoading: false, decorateData },
  );

export interface InvoiceAll {
  number: string,
  amount: number,
  dueDate: string,
  description: string | null,
  currency: string,
  conversionRate: number,
  roundDifference: number,
  status: InvoiceStatusType,
  order?: Order,
  services: ServicesDetails[],
}

export type InvoiceStatusType = 'invisible' | 'draft' | 'pending' | 'paid' | 'overdue' | 'expired';

interface ServicesDetails {
  id: string;
  name: string;
  value: number;
  valueCHF: number;
  tax: number;
  quantity: number;
  units: string;
  kind: ServiceKindType;
}

export type ServiceKindType = 'shippingCost' | 'platformUsageFee' | 'insuranceFee' | string;

export interface InvoicesTable extends Invoice {
  key: string;
}

export interface InvoicesTableData {
  data: InvoicesTable[];
  total: number;
}

export const useInvoicesTableGet = (rolePath: RolePathType) => useInvoicesGet<InvoicesTableData>(
  rolePath,
  ({ data, meta }: PagingDataResponse<Invoice>): InvoicesTableData => ({
    data: data.map((item: Invoice): InvoicesTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

export const useInvoicesOptionsGet = (rolePath: RolePathType) => useInvoicesGet<Option[]>(
  rolePath,
  ({ data }: PagingDataResponse<Invoice>): Option[] => (
    data.map((item) => (
      { value: item?.status || '', label: item?.status || '' }
    ))
  ),
);

export const useInvoicesGetById = <DD = Invoice>(
  rolePath: RolePathType,
  id?: string,
  decorateData?: (data: Invoice) => DD,
): FetchGetId<
  Invoice,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    `${rolePath}/invoices`,
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export interface InvoiceCreateParams {
  id: string,
}

export const useInvoiceResend = (
  rolePath: RolePathType,
  id?: string,
): FetchCreate<InvoiceCreateParams, DefaultFetchError> => (
  useFetchCreate(`${rolePath}/invoices/${id}/send-by-mail`)
);

export const useInvoiceMarkAsPaid = (
  rolePath: RolePathType,
  id?: string,
): FetchCreate<InvoiceCreateParams, DefaultFetchError> => (
  useFetchCreate(`${rolePath}/invoices/${id}/mark-as-paid`)
);

export const useInvoicesDelete = (rolePath: RolePathType, id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete(`${rolePath}/invoices`, id)
);

export interface InvoicesData {
  id: string;
  location: string;
  fileSize: number;
  originalName: string;
  mimeType: string;
  encoding: string;
}

export const useInvoiceDownload = (
  rolePath: RolePathType,
  id: string,
  abortController?: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    `${rolePath}/invoices/${id}/download-as-pdf`,
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController?.signal,
      },
    },
    'arraybuffer',
  )
);
