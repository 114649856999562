import React, { useEffect, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { DeliveryService, DeliveryTerms, ModeOfTransport } from '../../Adapter/enums';
import { Option } from '../../../../../types';
import { ServiceEnumDHL, ServiceEnumFedex } from '../../../../../enums/orderServices';
import { DeliveryServiceType } from '../../../../../hooks/api/tracking';
import { ClientDataGood } from '../../Adapter';

const disabledServiceOption: Option = {
  value: 'disabled',
  label: (
    <div style={{ whiteSpace: 'normal' }}>There are no available options for this delivery service. Pick other</div>
  ),
  disabled: true,
};

const useDeliveryOptions = (
  form: FormInstance,
  shipperForm: FormInstance,
  consigneeForm: FormInstance,
  orderTypeForm: FormInstance,
  modeOfTransport: ModeOfTransport,
  isUserAdmin: boolean,
  goodsWatch: ClientDataGood[],
  deliveryService: DeliveryServiceType | '',
  serviceTypeWatch: string | null,
  deliveryTerms: DeliveryTerms | null,
) => {
  const [serviceTypeOptions, setServiceTypeOptions] = useState<Option[]>([]);
  const [deliveryTermsOptions, setDeliveryTermsOptions] = useState<Option[]>([]);

  const countryShipper = Form.useWatch('country', shipperForm);
  const countryConsignee = Form.useWatch('country', consigneeForm);
  const productTypeWatch = Form.useWatch('productType', orderTypeForm) || (
    goodsWatch?.some((item) => item?.dangerousGoods) ? 'both' : 'regular'
  );

  const isProductsDangerous = ['dangerous', 'both'].includes(productTypeWatch);

  const handleChangeAccount = (accountNum: string) => {
    form.setFieldValue('accountNumber', accountNum);
    form.validateFields(['accountNumber']);
  };

  /** After options update check, is currently selected option still available. if not - clear value */
  useEffect(() => {
    if (serviceTypeOptions.length > 0
      && !serviceTypeOptions.some((option) => option.value === serviceTypeWatch)) {
      form.setFieldValue('serviceType', null);
    }

    /** Clears deliveryService if there's no service type options available with it. */
    /* if (Array.isArray(serviceTypeOptions) && serviceTypeOptions.length === 0) {
      form.setFieldValue('deliveryService', null);
      setServiceTypeOptions(undefined);
    } */
  }, [serviceTypeWatch, serviceTypeOptions]);

  useEffect(() => {
    /** If currently selected option not available anymore - clear field. */
    if (deliveryTermsOptions.length > 0
      && !deliveryTermsOptions.some((option) => option.value === deliveryTerms)) {
      form.setFieldValue('deliveryTerms', null);
    }
  }, [deliveryTerms, deliveryTermsOptions]);

  useEffect(() => {
    const callback = () => {
      const isDomestic = countryShipper === 'Switzerland' && countryConsignee === 'Switzerland';
      const fromSwissToAbroad = countryShipper === 'Switzerland' && countryConsignee !== 'Switzerland'; // Export
      const fromAbroadToSwiss = countryShipper !== 'Switzerland' && countryConsignee === 'Switzerland'; // Import
      const thirdCountries = countryShipper !== 'Switzerland' && countryConsignee !== 'Switzerland';

      switch (modeOfTransport) {
        case ModeOfTransport.ADR:
        case ModeOfTransport.RAIL:
        case ModeOfTransport.COURIER: { // Road Freight
          if (isDomestic) {
            switch (deliveryService?.toLowerCase()) {
              case DeliveryService.DHL: {
                handleChangeAccount('155571976');
                setServiceTypeOptions([
                  { value: 'N', label: ServiceEnumDHL.N }, // Express Domestic
                  // { value: 'Y', label: ServiceEnumDHL.Y }, // Express Domestic 12:00
                ]);

                break;
              }

              case DeliveryService.FEDEX: {
                setServiceTypeOptions([
                  { value: 'FEDEX_PRIORITY', label: ServiceEnumFedex.FEDEX_PRIORITY },
                  { value: 'FEDEX_PRIORITY_FREIGHT', label: ServiceEnumFedex.FEDEX_PRIORITY_FREIGHT },
                ]);

                break;
              }

              // SCHENKER & OTHER
              default: {
                handleChangeAccount('');
                setServiceTypeOptions([]);
              }
            }

            if (!isUserAdmin) {
              setDeliveryTermsOptions([]);
            }
          }
          if (fromSwissToAbroad) {
            switch (deliveryService?.toLowerCase()) {
              case DeliveryService.DHL: {
                handleChangeAccount('219733830');
                setServiceTypeOptions([
                  { value: 'P', label: ServiceEnumDHL.P }, // Express Worldwide
                  { value: 'H', label: ServiceEnumDHL.H }, // Economy Select
                ]);

                break;
              }

              case DeliveryService.FEDEX: {
                setServiceTypeOptions(!isProductsDangerous ? [
                  { value: 'FEDEX_REGIONAL_ECONOMY', label: ServiceEnumFedex.FEDEX_REGIONAL_ECONOMY },
                  { value: 'FEDEX_REGIONAL_ECONOMY_FREIGHT', label: ServiceEnumFedex.FEDEX_REGIONAL_ECONOMY_FREIGHT },
                ] : [disabledServiceOption]);

                break;
              }

              default: {
                handleChangeAccount('');
                setServiceTypeOptions([]);
              }
            }

            if (!isUserAdmin) {
              setDeliveryTermsOptions([
                { value: DeliveryTerms.DAP, label: DeliveryTerms.DAP },
                { value: DeliveryTerms.EXW, label: DeliveryTerms.EXW },
                { value: DeliveryTerms.DDP, label: DeliveryTerms.DDP },
              ]);
            }
          }
          if (fromAbroadToSwiss) {
            switch (deliveryService?.toLowerCase()) {
              case DeliveryService.DHL: {
                handleChangeAccount('966392290');
                setServiceTypeOptions([
                  { value: 'P', label: ServiceEnumDHL.P }, // Express Worldwide
                  { value: 'H', label: ServiceEnumDHL.H }, // Economy Select
                ]);

                break;
              }

              case DeliveryService.FEDEX: {
                setServiceTypeOptions(!isProductsDangerous ? [
                  { value: 'FEDEX_REGIONAL_ECONOMY', label: ServiceEnumFedex.FEDEX_REGIONAL_ECONOMY },
                  { value: 'FEDEX_REGIONAL_ECONOMY_FREIGHT', label: ServiceEnumFedex.FEDEX_REGIONAL_ECONOMY_FREIGHT },
                ] : [disabledServiceOption]);

                break;
              }

              default: {
                handleChangeAccount('');
                setServiceTypeOptions([]);
              }
            }

            if (!isUserAdmin) {
              setDeliveryTermsOptions([
                { value: DeliveryTerms.EXW, label: DeliveryTerms.EXW },
                { value: DeliveryTerms.FCA, label: DeliveryTerms.FCA },
              ]);
            }
          }
          if (thirdCountries) {
            switch (deliveryService?.toLowerCase()) {
              case DeliveryService.DHL: {
                handleChangeAccount('966392290');
                setServiceTypeOptions([
                  { value: 'P', label: ServiceEnumDHL.P },
                  { value: 'Q', label: ServiceEnumDHL.Q },
                ]);

                break;
              }

              case DeliveryService.FEDEX: {
                setServiceTypeOptions(!isProductsDangerous ? [
                  { value: 'FEDEX_REGIONAL_ECONOMY', label: ServiceEnumFedex.FEDEX_REGIONAL_ECONOMY },
                  { value: 'FEDEX_REGIONAL_ECONOMY_FREIGHT', label: ServiceEnumFedex.FEDEX_REGIONAL_ECONOMY_FREIGHT },
                ] : [disabledServiceOption]);

                break;
              }

              default: {
                handleChangeAccount('');
                setServiceTypeOptions([]);
              }
            }

            if (!isUserAdmin) {
              setDeliveryTermsOptions([
                { value: DeliveryTerms.DAP, label: DeliveryTerms.DAP },
                { value: DeliveryTerms.EXW, label: DeliveryTerms.EXW },
                { value: DeliveryTerms.DDP, label: DeliveryTerms.DDP },
              ]);
            }
          }
          break;
        }

        case ModeOfTransport.IATA: { // IATA only for export and import. Air Freight
          if (isDomestic) { // Air freight can't be from Swiss to Swiss, so we clear options in this case.
            handleChangeAccount('');
            setDeliveryTermsOptions([]);
            // setServiceTypeOptions([]);
          }
          if (fromSwissToAbroad) {
            switch (deliveryService?.toLowerCase()) {
              case DeliveryService.DHL: {
                handleChangeAccount('155571976');
                setServiceTypeOptions([
                  { value: 'P', label: ServiceEnumDHL.P },
                  { value: 'Q', label: ServiceEnumDHL.Q },
                ]);

                break;
              }

              case DeliveryService.FEDEX: {
                const dangerousAvailableOptions: Option[] = [
                  { value: 'FEDEX_INTERNATIONAL_PRIORITY', label: ServiceEnumFedex.FEDEX_INTERNATIONAL_PRIORITY },
                  {
                    value: 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS',
                    label: ServiceEnumFedex.FEDEX_INTERNATIONAL_PRIORITY_EXPRESS,
                  },
                ];

                setServiceTypeOptions(isProductsDangerous ? [
                  ...dangerousAvailableOptions,
                ] : [
                  ...dangerousAvailableOptions,
                  { value: 'INTERNATIONAL_ECONOMY_FREIGHT', label: ServiceEnumFedex.INTERNATIONAL_ECONOMY_FREIGHT },
                  { value: 'INTERNATIONAL_ECONOMY', label: ServiceEnumFedex.INTERNATIONAL_ECONOMY },
                ]);

                break;
              }

              default: {
                handleChangeAccount('');
                setServiceTypeOptions([]);
              }
            }

            if (!isUserAdmin) {
              setDeliveryTermsOptions([
                { value: DeliveryTerms.DAP, label: DeliveryTerms.DAP },
                { value: DeliveryTerms.EXW, label: DeliveryTerms.EXW },
                { value: DeliveryTerms.DDP, label: DeliveryTerms.DDP },
              ]);
            }
          }
          if (fromAbroadToSwiss) {
            switch (deliveryService?.toLowerCase()) {
              case DeliveryService.DHL: {
                handleChangeAccount('957226485');
                setServiceTypeOptions([
                  { value: 'P', label: ServiceEnumDHL.P },
                  { value: 'H', label: ServiceEnumDHL.H },
                ]);

                break;
              }

              case DeliveryService.FEDEX: {
                const dangerousAvailableOptions: Option[] = [
                  { value: 'FEDEX_INTERNATIONAL_PRIORITY', label: ServiceEnumFedex.FEDEX_INTERNATIONAL_PRIORITY },
                  {
                    value: 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS',
                    label: ServiceEnumFedex.FEDEX_INTERNATIONAL_PRIORITY_EXPRESS,
                  },
                ];

                setServiceTypeOptions(isProductsDangerous ? [
                  ...dangerousAvailableOptions,
                ] : [
                  ...dangerousAvailableOptions,
                  { value: 'INTERNATIONAL_ECONOMY_FREIGHT', label: ServiceEnumFedex.INTERNATIONAL_ECONOMY_FREIGHT },
                  { value: 'INTERNATIONAL_ECONOMY', label: ServiceEnumFedex.INTERNATIONAL_ECONOMY },
                ]);

                break;
              }

              default: {
                handleChangeAccount('');
                setServiceTypeOptions([]);
              }
            }

            if (!isUserAdmin) {
              setDeliveryTermsOptions([
                { value: DeliveryTerms.EXW, label: DeliveryTerms.EXW },
                { value: DeliveryTerms.FCA, label: DeliveryTerms.FCA },
              ]);
            }
          }
          if (thirdCountries) {
            switch (deliveryService?.toLowerCase()) {
              case DeliveryService.DHL: {
                handleChangeAccount('957226485');
                setServiceTypeOptions([
                  { value: 'P', label: ServiceEnumDHL.P },
                  { value: 'Q', label: ServiceEnumDHL.Q },
                ]);

                break;
              }

              case DeliveryService.FEDEX: {
                const dangerousAvailableOptions: Option[] = [
                  { value: 'FEDEX_INTERNATIONAL_PRIORITY', label: ServiceEnumFedex.FEDEX_INTERNATIONAL_PRIORITY },
                  {
                    value: 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS',
                    label: ServiceEnumFedex.FEDEX_INTERNATIONAL_PRIORITY_EXPRESS,
                  },
                ];

                setServiceTypeOptions(isProductsDangerous ? [
                  ...dangerousAvailableOptions,
                ] : [
                  ...dangerousAvailableOptions,
                  { value: 'INTERNATIONAL_ECONOMY_FREIGHT', label: ServiceEnumFedex.INTERNATIONAL_ECONOMY_FREIGHT },
                  { value: 'INTERNATIONAL_ECONOMY', label: ServiceEnumFedex.INTERNATIONAL_ECONOMY },
                ]);

                break;
              }

              default: {
                handleChangeAccount('');
                setServiceTypeOptions([]);
              }
            }

            if (!isUserAdmin) {
              setDeliveryTermsOptions([
                { value: DeliveryTerms.DAP, label: DeliveryTerms.DAP },
                { value: DeliveryTerms.EXW, label: DeliveryTerms.EXW },
                { value: DeliveryTerms.DDP, label: DeliveryTerms.DDP },
              ]);
            }
          }
          break;
        }

        /** Sea Freight / IMDG-IMO - Only for DBSchenker */
        case ModeOfTransport.IMDG: {
          handleChangeAccount('');
          setServiceTypeOptions([{
            value: 'disabled',
            label: (
              <div style={{ whiteSpace: 'normal' }}>
                &quot;Sea Freight&quot; options available only with &quot;DB Schenker&quot; delivery service.
                <br />

                Change Mode of transport or use DB Schenker.
              </div>
            ),
            disabled: true,
          }]);
          setDeliveryTermsOptions([]);
          break;
        }

        default: {
          setDeliveryTermsOptions([]);
          setServiceTypeOptions([]);
        }
      }

      if (deliveryService?.toLowerCase() === DeliveryService.FEDEX) {
        handleChangeAccount('725902689');
      }

      // Reset presets if delivery service is schenker or other
      if (deliveryService?.toLowerCase() === DeliveryService.SCHENKER
        || deliveryService?.toLowerCase() === DeliveryService.OTHER) {
        setDeliveryTermsOptions([]);
        setServiceTypeOptions([]);
      }
    };

    const timeoutId = setTimeout(callback, 300);

    return () => clearTimeout(timeoutId);
  }, [modeOfTransport, deliveryService, countryShipper, countryConsignee, productTypeWatch]);

  return { serviceTypeOptions, deliveryTermsOptions, isProductsDangerous };
};

export default useDeliveryOptions;
