import React, { useEffect, useMemo } from 'react';
import {
  Button, DatePicker, Form, Input, InputNumber, Select,
} from 'antd';
import dayjs from 'dayjs';
import { CalculatorOutlined } from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import { Rule } from 'rc-field-form/lib/interface';
import SelectModeTransport from '../../General/SelectModeTransport';
import { dateFormat, SelectYesValue } from '../../../../../../contstant';
import RangePickerTime from '../../../../../Common/RangePickerTime';
import { FormName, useOrderContextForm } from '../../context';
import SelectDeliveryTerms from '../../General/SelectDeliveryTerms';
import OrderInput from '../../Common/Input';
import SelectTransportInsurance from '../../General/SelectTransportInsurance';
import { Option } from '../../../../../../types';
import { disabledToEditOrderStatuses, disabledToEditUserOrderStatuses } from '../../../Adapter/enums';
import { useOrderContext } from '../../../View/context';
import { isRoleEnough } from '../../../../../../enums/user';
import { useAuth } from '../../../../../../store/auth';

import styles from '../../index.module.scss';

interface DeliveryCustomerFieldsProps extends FormName {
  deliveryTermsOptions: Option[];
  serviceTypeOptions?: Option[];
}

function DeliveryCustomerFields({ formName, deliveryTermsOptions }: DeliveryCustomerFieldsProps) {
  const {
    forms: {
      [formName]: form, goods: formGoods,
      orderType: orderTypeForm, shipper: shipperForm, importer: consigneeForm,
    },
    initialStates, triggerValidationAllForm,
  } = useOrderContextForm();
  const { clientOrderData } = useOrderContext();
  const { user } = useAuth();

  const countryShipper = Form.useWatch('country', shipperForm);
  const countryConsignee = Form.useWatch('country', consigneeForm);

  const isDomestic = countryShipper === 'Switzerland' && countryConsignee === 'Switzerland';

  const isAssistanceNeeded = Form.useWatch('isAssistanceNeeded', orderTypeForm);
  const fieldsRules: Rule[] | undefined = isAssistanceNeeded ? undefined : [{ required: true, message: 'Required' }];

  const deliveryService = Form.useWatch('deliveryService', form);
  const insuranceCurrency = Form.useWatch('insuranceCurrency', form);
  const insurance = Form.useWatch('insurance', form);

  const calcTransportInsurance = () => {
    const { goods } = formGoods.getFieldsValue();

    if (form && goods && goods.length > 0) {
      let sum = 0;

      goods.forEach((good: AnyObject) => {
        sum += (Number.parseFloat(good.value) || 0) * (Number.parseFloat(good.quantity) || 1);
      });

      form.setFieldValue('insuranceValue', sum);
    }
  };

  useEffect(() => {
    const value = form.getFieldValue('insuranceValue');

    if (Object.values(initialStates).length > 0 && (value === '' || value === null || value === undefined)) {
      calcTransportInsurance();
    }
  }, [initialStates]);

  const isFieldsDisabled = useMemo(() => (
    clientOrderData?.status
    && (
      isRoleEnough(user?.role, 'admin') ? disabledToEditOrderStatuses : disabledToEditUserOrderStatuses
    ).includes(clientOrderData?.status)
  ), [clientOrderData?.status, deliveryService]);

  return (
    <div>
      <SelectModeTransport
        label="Mode of transport"
        name="modeOfTransport"
        rightText="DGA, Delivery"
        rules={fieldsRules}
        disableAirFreight={isDomestic}
        selectProps={{ disabled: isFieldsDisabled }}
      />
      <Form.Item
        label="Consignment date"
        name="consignmentDate"
        initialValue={dayjs().utc()}
        extra={(
          <div className={styles.text}>
            DGA, PI, Delivery
          </div>
        )}
      >
        <DatePicker format={dateFormat} defaultValue={dayjs().utc()} />
      </Form.Item>
      <Form.Item
        label="Pickup date"
        name="pickupDate"
        initialValue={dayjs().utc()}
        extra={<div className={styles.text} />}
        // rules={fieldsRules}
      >
        <DatePicker
          format={dateFormat}
          defaultValue={dayjs().utc()}
          onChange={(date) => {
            if (!date) {
              form.setFieldValue('pickupTime', null);
            }
          }}
        />
      </Form.Item>
      <Form.Item
        label="Pickup time"
        name="pickupTime"
        extra={<div className={styles.text} />}
        // rules={fieldsRules}
      >
        <RangePickerTime />
      </Form.Item>
      {/* Separator */}
      <SelectDeliveryTerms
        label="Delivery terms"
        name="deliveryTerms"
        rightText="PI, Delivery"
        rules={fieldsRules}
        selectProps={{
          ...(deliveryTermsOptions.length > 0 ? { options: deliveryTermsOptions } : {}),
          disabled: isFieldsDisabled,
        }}
      />
      <OrderInput
        label="Billing account number"
        name="billingNumber"
        rightText="Delivery"
        // rules={fieldsRules}
        inputProps={{ disabled: isFieldsDisabled }}
      />
      <SelectTransportInsurance
        label="Transport insurance"
        name="insurance"
        rightText="PI, Delivery"
        // rules={fieldsRules}
        selectProps={{ disabled: isFieldsDisabled }}
      />
      <Form.Item
        style={{ display: 'none' }}
        initialValue="CHF"
        name="insuranceCurrency"
        // rules={fieldsRules}
      >
        <Input disabled={isFieldsDisabled} />
      </Form.Item>
      <Form.Item
        label="Transport insurance value"
        name="insuranceValue"
        className={styles.btnCalc}
        extra={(
          <>
            <Button
              disabled={insurance !== SelectYesValue.value}
              style={{
                marginLeft: 8, padding: '2px 0 0', minWidth: 32, minHeight: 32,
              }}
              icon={<CalculatorOutlined />}
              onClick={() => {
                calcTransportInsurance();
                triggerValidationAllForm();
              }}
            />
            <div className={styles.text}>
              PI, Delivery
            </div>
          </>
        )}
        // rules={insurance ? fieldsRules : undefined}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
      >
        <InputNumber
          disabled={insurance !== SelectYesValue.value || isFieldsDisabled}
          addonAfter={(
            <Select
              value={insuranceCurrency}
              onChange={(value) => {
                form.setFieldValue('insuranceCurrency', value);
                setTimeout(() => form.validateFields(), 0);
              }}
              disabled={insurance !== SelectYesValue.value}
              defaultValue="CHF"
            >
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="CHF">CHF</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
          )}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        label="24h emergency number"
        name="emergencyPhoneNumber"
        // rules={fieldsRules}
      >
        <Input maxLength={100} disabled={isFieldsDisabled} />
      </Form.Item>
      <OrderInput
        label="Important notes for Sephyre"
        name="notes"
        textarea
        textAreaProps={{ disabled: isFieldsDisabled }}
      />
    </div>
  );
}

// DeliveryCustomerFields.TopForm = TopForm;
// DeliveryCustomerFields.BottomForm = BottomForm;

export default DeliveryCustomerFields;
