import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { userMe as userMeAction } from '@triare/auth-redux/dist/saga/auth/useMe';
import { Action, refreshToken as refreshTokenAction, RefreshTokenAction } from '@triare/auth-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import TermsOfServiceModal from './TermsOfService';
import { useCompanyClientGet, useCompanyCreate, useCompanyUpdate } from '../../../../hooks/api/company';
import CompanyForm from '../Form';
import { useCompanyContext } from '../Form/context';
import { RootState } from '../../../../store';
import { moduleName, User } from '../../../../store/auth';
import { useMessageError } from '../../../../hooks/common';
import { useUpdateMe } from '../../../../hooks/api/users';

interface CreateCompanyProps {
  user: User | null;
  userMe: () => Action<UnknownAction>;
  refreshToken: (payload?: RefreshTokenAction) => Action<UnknownAction>;
}

function CreateCompanyProfileContent({ user, userMe, refreshToken }: CreateCompanyProps) {
  const navigate = useNavigate();
  const {
    form, isValid, setValid, setInitialState, initialState,
  } = useCompanyContext();
  const companyCreate = useCompanyCreate('client');
  const companyUpdate = useCompanyUpdate(undefined, user?.role === 'user');
  const companyClient = useCompanyClientGet();
  const updateMe = useUpdateMe();

  const handleSubmit = () => {
    if (form) {
      form.validateFields().then((values) => {
        setInitialState(values);
        const fetchFunction = !user?.company?.id ? companyCreate.fetch : companyUpdate.fetch;

        fetchFunction(values).then((res) => {
          if (res?.id) {
            /** After success company confirmation update user info. */
            updateMe.fetch({ isCompanyAccepted: true, isTermsOfServiceAccepted: true })
              .then((resUser) => {
                if (resUser?.id) {
                /** If request finished with success, refresh user-info, and he will get redirected */
                  refreshToken(); /** After company creation we need to update token, to add company data in token. */
                  setTimeout(() => userMe(), 200);
                }
              });
          }
        });
      });
    }
  };

  useEffect(() => {
    if (user?.company?.id) {
      navigate('/', { replace: true });
    }

    if (user && form) {
      form.setFieldValue('contactName', user.fullName || '');
    }
  }, [user, form]);

  /** Handling Update view (when user came from 'form') */
  useEffect(() => {
    if (!user?.isCompanyAccepted && user?.company?.id) {
      companyClient.fetch();
    }
  }, [user?.company?.id]);

  useEffect(() => {
    if (companyClient.data) {
      setInitialState(companyClient.data);
    }
  }, [companyClient.data]);

  useEffect(() => {
    if (form) {
      form.setFieldsValue(companyClient.data);
      form.validateFields();
      setValid(true);
    }
  }, [initialState]);

  useMessageError([companyCreate, companyUpdate, updateMe]);

  return (
    <>
      <TermsOfServiceModal />
      <div
        className={clsx('content-container')}
      >
        <CompanyForm
          createProfileView
          footer={(
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={!isValid}
              loading={companyCreate.loading || companyUpdate.loading}
            >
              Save
            </Button>
          )}
        />
      </div>
    </>
  );
}

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}), {
  userMe: userMeAction,
  refreshToken: refreshTokenAction,
})(CreateCompanyProfileContent);
