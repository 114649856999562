import React, { useState } from 'react';
import { Badge, BadgeProps, Col } from 'antd';
import dayjs from 'dayjs';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EuroCircleOutlined,
  ExclamationCircleFilled,
  InfoCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import InvoicesModal from '../../../Invoices/View';
import { useOrderContext } from '../context';
import { capitalize } from '../../../../../utils';
import { getInvoiceStatusColor } from '../../../Invoices';
import IconWithBadge from '../../../../Common/IconWithBadge';
import { Invoice, InvoiceStatusType } from '../../../../../hooks/api/invoices';
import { isRoleEnough } from '../../../../../enums/user';
import { useAuth, UserRole } from '../../../../../store/auth';

import styles from './index.module.scss';

type InvoiceStatusReturnType = { color: BadgeProps['color'], icon: React.ElementType };

export const getInvoiceStatusIcon = (status?: InvoiceStatusType): InvoiceStatusReturnType => {
  switch (status) {
    case 'invisible': /** Inivisible should not be displayed in list */
      return {
        icon: MinusCircleFilled,
        color: '',
      };

    case 'draft':
      return {
        icon: MinusCircleFilled,
        color: '',
      };

    case 'pending':
      return {
        icon: InfoCircleFilled,
        color: 'gold',
      };

    case 'paid':
      return {
        icon: CheckCircleFilled,
        color: 'green',
      };

    case 'expired':
      return {
        icon: CloseCircleFilled,
        color: 'red',
      };

    case 'overdue':
      return {
        icon: ExclamationCircleFilled,
        color: 'red',
      };

    default: // Draft
      return {
        icon: MinusCircleFilled,
        color: '',
      };
  }
};

export const getVisibleInvoices = (invoices: Invoice[], userRole?: UserRole) => (
  invoices
    .filter((item) => !(['invisible'] as InvoiceStatusType[]).includes(item.status))
    .filter((item) => isRoleEnough(userRole, 'admin')
      || !(['draft', 'expired'] as InvoiceStatusType[]).includes(item.status))
);

export function getPrioritizedInvoice(visibleInvoices: Invoice[]): Invoice | undefined {
  if (visibleInvoices?.length === 0) return undefined;

  const statusPriority: Record<InvoiceStatusType, number> = {
    overdue: 1,
    pending: 2,
    paid: 3,
    draft: 4,
    expired: 5,
    invisible: 6, // This shouldn't appear since invisible gets filtered it out
  };

  return visibleInvoices.reduce((prev, current) => (
    statusPriority[current.status] < statusPriority[prev.status] ? current : prev), visibleInvoices[0]);
}

function InvoicesList() {
  const { user } = useAuth();
  const { order, clientOrderData } = useOrderContext();
  const [modalState, setModalState] = useState({ open: false, invoiceId: '' });

  const handleOpen = ({ open, id }: { open: boolean, id: string }) => {
    setModalState({ open, invoiceId: id });
  };

  return (
    <div>
      {clientOrderData?.invoices
        ?.filter((item) => item.status !== 'invisible')
        ?.length ? (
          // clientOrderData?.invoices
          //   .filter((item) => !(['invisible'] as InvoiceStatusType[]).includes(item.status))
          //   .filter((item) => isRoleEnough(user?.role, 'admin')
          //     || !(['draft', 'expired'] as InvoiceStatusType[]).includes(item.status))
          getVisibleInvoices(clientOrderData.invoices, user?.role)
            .map((invoice) => {
              const iconConfig = getInvoiceStatusIcon(invoice.status);

              return (
                <div
                  key={invoice.id}
                  className={styles.item}
                  tabIndex={-1}
                  role="button"
                  onClick={() => handleOpen({ open: true, id: invoice.id })}
                  style={{ cursor: 'pointer' }}
                >
                  <div className={styles.icon}>
                    <IconWithBadge
                      BadgeIcon={iconConfig.icon}
                      badgeColor={iconConfig.color}
                      Icon={EuroCircleOutlined}
                    />
                  </div>

                  <Col>
                    <div className={styles.invoiceNumber}>
                      {`Invoice ${invoice.number || '-'}`}
                    </div>
                    <div className={styles.date}>
                      {`Created: ${dayjs(invoice.createdAt).format('DD/MM/YYYY')}`}
                    </div>
                  </Col>

                  <div style={{ textAlign: 'right' }}>
                    <Badge
                      color={getInvoiceStatusColor(invoice.status)}
                      text={capitalize(invoice?.status)}
                    />
                  </div>
                </div>
              );
            })
        ) : (
          <div className="text-secondary">
            No documents yet
          </div>
        )}

      <InvoicesModal
        showFooter={false}
        id={modalState.invoiceId}
        isModalOpen={modalState.open}
        // setModalOpen={setModalState}
        handleClose={() => handleOpen({ open: false, id: '' })}
        afterAction={() => order.fetch()}
      />
    </div>
  );
}

export default InvoicesList;
