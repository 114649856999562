import React, {
  useEffect, useMemo, useState,
} from 'react';
import * as yup from 'yup';
import clsx from 'clsx';
import {
  Button, Checkbox, Col, Form, Input, InputNumber,
} from 'antd';
import { ExclamationCircleFilled, MailOutlined, ReloadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import { Row } from 'antd/lib';
import { Rule } from 'rc-field-form/lib/interface';
import { FormName, useOrderContextForm } from '../context';
import { createRulesForAntd, createValidatorTextField, textField } from '../../../../../utils/validations';
import SelectDeliveryService from '../Common/SelectDeliveryService';
import OrderInput, { OrderInputProps } from '../Common/Input';
import Tracking from '../../Tracking';
import { FormWrapper } from '../index';
import {
  useIntegrationsShipmentCancel,
  useIntegrationsShipmentCreate,
  useOrderAccountNumbersWithLabel,
  useOrderMarkAsDelivered,
  useOrderSendTrackingLink,
} from '../../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import SelectShipmentCreation from '../Common/SelectShipmentCreation';
import SelectServiceType from '../Common/SelectServiceType';
import { useOrderContext } from '../../View/context';
import {
  DeliveryService,
  DeliveryTerms,
  disabledToEditOrderStatuses,
  disabledToEditUserOrderStatuses,
  ModeOfTransport,
  OrderStatus,
} from '../../Adapter/enums';
import OrderSelect, { OrderSelectProps } from '../Common/Select';
import SelectAirport from '../Common/SelectAirport';
import SelectPort from '../Common/SelectPort';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import OrderDeliveryDHLInput from '../Common/InputDHLDelivery';
import {
  AddedServicesProps, CheckboxesServicesState, ClientDataGood, ClientDataPackages,
} from '../../Adapter';
import { ValueAddedService } from '../../Adapter/types';
import { DHLOptionalServiceCodes } from '../../../../../enums/orderServices';
import {
  createDangerousGoodsObject, getFormattedUnNumbers, handleDryIceChange, handleUnNumbersChange, serviceDefinitions,
} from './utils';
import DeliveryCustomerFields from './CustomerFields';
import { useAuth } from '../../../../../store/auth';
import { isRoleEnough } from '../../../../../enums/user';
import { DeliveryServiceType } from '../../../../../hooks/api/tracking';
import useDeliveryOptions from './useDeliveryOptions';

import commonStyles from '../index.module.scss';
import styles from './index.module.scss';

type DeliveryProps = FormName;

/** Returns count + word 'package(s)' adjusted depending on */
function getPackageCountString(count: number): string {
  return `${count || 1} ${count === 1 ? 'package' : 'packages'}`;
}

// const defaultCheckboxesValue: CheckboxesServicesState = {
//   [DHLOptionalServiceCodes.adultSignature]: false,
//   [DHLOptionalServiceCodes.neutralDelivery]: false,
//   [DHLOptionalServiceCodes.directSignature]: false,
//   [DHLOptionalServiceCodes.noSignatureRequired]: false,
//   [DHLOptionalServiceCodes.brokerNotification]: false,
//   [DHLOptionalServiceCodes.dangerousGoods]: false,
//   [DHLOptionalServiceCodes.limitedQuantity]: false,
//   [DHLOptionalServiceCodes.exceptedQuantity]: false,
//   [DHLOptionalServiceCodes.dryIce]: false,
//   [DHLOptionalServiceCodes.lithiumMetal969]: false,
//   [DHLOptionalServiceCodes.lithiumIon966]: false,
//   [DHLOptionalServiceCodes.lithiumIon967]: false,
//   [DHLOptionalServiceCodes.lithiumMetal970]: false,
// };
const defaultCheckboxesValue: CheckboxesServicesState = Object.keys(DHLOptionalServiceCodes).reduce((acc, key) => {
  const code = DHLOptionalServiceCodes[key as keyof typeof DHLOptionalServiceCodes];

  acc[code] = false;

  return acc;
}, {} as CheckboxesServicesState);

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);

export default function Delivery({ formName }: DeliveryProps): React.ReactNode | null {
  const { id } = useParams();
  const { user } = useAuth();
  const { open, contextHolder } = useSimpleModal();
  const {
    setValidator, forms: { [formName]: form, general: generalForm },
    initialStates: { [formName]: initialState },
    orderSave, orderUpdate, orderCreate,
    goodsForm, shipperForm, consigneeForm, orderTypeForm, packagesForm,
  } = useOrderContextForm();
  const [_, setSearchParams, validParams] = useSearchParams();
  const [trigger, setTrigger] = useState<number>(0);
  const { clientOrderData, order, handleClientOrderData } = useOrderContext();

  const isUserAdmin = isRoleEnough(user?.role, 'admin');

  const deliveryService: DeliveryServiceType | '' = Form.useWatch('deliveryService', form);
  const accountNumberWatch = Form.useWatch('accountNumber', form);
  const serviceTypeWatch = Form.useWatch('serviceType', form);

  // if we have general form value - then use it, if dont - use delivery form value;
  const modeOfTransport: ModeOfTransport = Form.useWatch('modeOfTransport', isUserAdmin ? generalForm : form);
  const deliveryTerms: DeliveryTerms = Form.useWatch('deliveryTerms', isUserAdmin ? generalForm : form);

  const isAssistanceNeeded = Form.useWatch('isAssistanceNeeded', orderTypeForm);
  const fieldsRules: Rule[] | undefined = isAssistanceNeeded ? undefined : [{ required: true, message: 'Required' }];

  const trackingLink = Form.useWatch('trackingLink', form);
  const trackingNumber = Form.useWatch('trackingNumber', form);
  const shipmentCreation = Form.useWatch('shipmentCreation', form);
  const orderSendTrackingLink = useOrderSendTrackingLink(id);
  const orderMarkAsDelivered = useOrderMarkAsDelivered(id);
  const integrationsShipmentCreate = useIntegrationsShipmentCreate(id);
  const integrationsShipmentCancel = useIntegrationsShipmentCancel(id);
  const orderAccountNumbersWithLabel = useOrderAccountNumbersWithLabel();
  const goodsWatch: ClientDataGood[] = Form.useWatch('goods', goodsForm) || [];
  const packagesWatch: ClientDataPackages[] = Form.useWatch('packages', packagesForm) || [];

  const addedServicesCheckboxesWatch: CheckboxesServicesState = Form.useWatch(['addedServices', 'checkboxes'], form)
    || defaultCheckboxesValue;
  const addedServicesWatch: AddedServicesProps = Form.useWatch(['addedServices'], form);

  useMessageError([orderSendTrackingLink, orderAccountNumbersWithLabel, orderMarkAsDelivered]);

  useMessageError([integrationsShipmentCreate, integrationsShipmentCancel], 60);
  useMessageSuccess([orderSendTrackingLink], 'Tracking link sent successfully');
  useMessageSuccess([integrationsShipmentCreate], 'Shipment created successfully');
  useMessageSuccess([integrationsShipmentCancel], 'Shipment canceled successfully');
  useMessageSuccess([orderMarkAsDelivered], 'Mark as delivered successfully');

  useEffect(() => {
    if (deliveryService) {
      orderAccountNumbersWithLabel.fetch({
        deliveryService,
      });
    }
  }, [deliveryService]);

  /** Requests which don't have updated entity in response trigger order.fetch() to update form state */
  useEffect(() => {
    if ((orderSendTrackingLink.data && !orderSendTrackingLink.error && !orderSendTrackingLink.loading)
      || (orderMarkAsDelivered.data && !orderMarkAsDelivered.error && !orderMarkAsDelivered.loading)
    ) {
      order.fetch();
    }
  }, [orderSendTrackingLink.response, orderMarkAsDelivered.response]);

  /** Requests which have updated entity in response instantly updates order-data state if there's no error */
  useEffect(() => {
    if (integrationsShipmentCreate.data && !integrationsShipmentCreate.error && !integrationsShipmentCreate.loading) {
      handleClientOrderData(integrationsShipmentCreate.data);
      integrationsShipmentCreate.clearResponse(); // Clear response state after setting it in form.
    }
    if (integrationsShipmentCancel.data && !integrationsShipmentCancel.error && !integrationsShipmentCancel.loading) {
      handleClientOrderData(integrationsShipmentCancel.data);
      integrationsShipmentCancel.clearResponse();
    }
  }, [integrationsShipmentCreate.response, integrationsShipmentCancel.response]);

  useEffect(() => {
    if (trigger
      && deliveryService
      && orderAccountNumbersWithLabel.data?.length
      && !orderAccountNumbersWithLabel.error
      && !orderAccountNumbersWithLabel.loading
    ) {
      setTrigger(0);
      integrationsShipmentCreate.fetch({
        deliveryService,
        shipmentCreation: form.getFieldValue('shipmentCreation'),
        accountNumber: form.getFieldValue('accountNumber') || orderAccountNumbersWithLabel.data?.[0]?.account,
        serviceType: form.getFieldValue('serviceType'),
        serviceName: form.getFieldValue('serviceName') || clientOrderData?.delivery?.serviceName || '',
        trackingLink: form.getFieldValue('trackingLink') || clientOrderData?.delivery?.trackingLink || '',
        trackingNumber: form.getFieldValue('trackingNumber') || clientOrderData?.delivery?.trackingNumber || '',
      });
    }
  }, [orderAccountNumbersWithLabel.response, trigger]);

  useEffect(() => {
    setValidator(formName, () => !(Object.keys(form.getFieldsValue())
      .filter((key) => !(['deliveryService'].includes(key)))
      .map((key) => form.getFieldValue(key))
      .some((value) => value === '' || value === undefined)
        || form.getFieldsError().some(({ errors }) => errors.length)));
  }, [form]);

  const rules = {
    deliveryService: textField,
  };

  if (deliveryService?.toLowerCase() === DeliveryService.OTHER) {
    Object.assign(rules, {
      serviceName: textFieldRequired,
      trackingNumber: textFieldRequired,
      trackingLink: textFieldRequired,
    });
  }

  if (deliveryService?.toLowerCase() === DeliveryService.FEDEX) {
    Object.assign(rules, {
      shipmentCreation: textFieldRequired,
      serviceType: shipmentCreation === 'Manually' ? undefined : textFieldRequired,
      trackingNumber: shipmentCreation === 'Manually' ? textFieldRequired : undefined,
    });
  }

  if (deliveryService?.toLowerCase() === DeliveryService.DHL) {
    Object.assign(rules, {
      shipmentCreation: textFieldRequired,
      accountNumber: textFieldRequired,
      serviceType: shipmentCreation !== 'Manually' ? textFieldRequired : undefined,
      trackingNumber: shipmentCreation === 'Manually' ? textFieldRequired : undefined,
    });
  }

  if (deliveryService?.toLowerCase() === DeliveryService.SCHENKER) {
    Object.assign(rules, {
      shipmentCreation: textFieldRequired,
      serviceType: textFieldRequired,
      trackingNumber: shipmentCreation === 'Manually' ? textFieldRequired : undefined,
    });

    if (modeOfTransport === ModeOfTransport.IMDG) {
      Object.assign(rules, {
        portDischarge: textFieldRequired,
        portLoading: textFieldRequired,
      });
    }

    if (modeOfTransport === ModeOfTransport.IATA) {
      Object.assign(rules, {
        departureAirport: textFieldRequired,
        destinationAirport: textFieldRequired,
      });
    }
  }

  const validationRules = createRulesForAntd(yup.object().shape(rules));

  // TODO update in which statuses it dissapears for admin.
  const createShipmentButton = clientOrderData?.status && [
    OrderStatus.PROCESSING,
    OrderStatus.REJECTED,
    OrderStatus.SHIPPED_OUT,
    OrderStatus.ON_THE_WAY,
    OrderStatus.DELIVERED,
  ].includes(clientOrderData?.status) ? null : (
    <Button
      icon={<MailOutlined />}
      disabled={orderAccountNumbersWithLabel.loading}
      loading={integrationsShipmentCreate.loading || orderUpdate.loading || orderCreate.loading}
      type="default"
      onClick={(e) => {
        e.preventDefault();
        orderSave(e, () => {
          setTrigger(Date.now());
        });
      }}
    >
      Create shipment
    </Button>
    );

  const handleCancelShipment = (e: React.MouseEvent<HTMLElement>) => {
    open({
      title: 'Cancel shipment',
      content: (
        <span>
          Are you sure you want to cancel this shipment?
          {' '}
          <br />
          This action cannot be undone.
        </span>
      ),
      cancelText: 'Cancel',
      okText: 'Cancel Shipment',
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => orderSave(e, () => {
        integrationsShipmentCancel.fetch();
      }),
    });
  };

  const cancelShipmentButton = [
    'Processing',
  ].includes(clientOrderData?.status || '') ? (
    <Button
      danger
      type="primary"
      onClick={handleCancelShipment}
      loading={integrationsShipmentCreate.loading || integrationsShipmentCancel.loading
        || orderUpdate.loading || orderCreate.loading}
      disabled={false}
    >
      Cancel shipment
    </Button>
    ) : null;

  const preferredDelivery = Form.useWatch('preferredDeliveryService', generalForm);

  useEffect(() => {
    /** If NO initial state deliveryService, AND not changed after this automatic set,then upd on delivery 'tab open' */
    if (validParams?.tab === 'Delivery' && !initialState?.deliveryService
      && !shipmentCreation && (deliveryService !== 'other' && deliveryService !== 'schenker')
    ) {
      form.setFieldValue('deliveryService', preferredDelivery);
    }
  }, [preferredDelivery, validParams?.tab]);

  const isFieldsDisabled = useMemo(() => (
    clientOrderData?.status
    && (isUserAdmin ? disabledToEditOrderStatuses : disabledToEditUserOrderStatuses).includes(clientOrderData?.status)
  ), [clientOrderData?.status, deliveryService]);

  const defaultSelectProps: Partial<OrderSelectProps> = {
    // rules: [{ required: true, message: <div /> }],
    rightText: false,
    selectProps: { disabled: isFieldsDisabled },
  };

  const defaultInputProps: Partial<OrderInputProps> = {
    rightText: false,
    inputProps: { disabled: isFieldsDisabled },
  };

  const customerShipmentSelectProps: Partial<OrderSelectProps> = !isUserAdmin ? {
    initialValue: 'Automatically',
    style: { display: 'none' },
  } : {};

  const getOpenTrackingLink = (deliveryServ: DeliveryService): string => {
    switch (deliveryServ) {
      case DeliveryService.DHL: {
        return `https://www.dhl.com/ua-en/home/tracking.html?tracking-id=${initialState?.trackingNumber}`;
      }
      case DeliveryService.FEDEX: {
        return `https://www.fedex.com/shippingplus/en-de/shipment/view/${
          initialState?.trackingNumber?.slice(0, 8)
        }-${
          initialState?.trackingNumber?.slice(-4)
        }-0000-0000-000000000000?context=shipments-overview`;
      }
      case DeliveryService.SCHENKER: {
        return 'https://www.dbschenker.com';
      }
      case DeliveryService.OTHER: {
        return '';
      }
      default:
        return '';
    }
  };

  /** Optional services \/ */

  const handleDryIceSum = () => {
    const totalNetWeightOfDryIce = goodsWatch
      .reduce((total: number, item: { netWeightOfDryIce: number; }) => total + (item?.netWeightOfDryIce || 0), 0);
    const roundedTotal = Number.parseFloat(totalNetWeightOfDryIce.toFixed(3)) || 0.001;

    form.setFieldValue(['addedServices', 'dryIceKg'], roundedTotal);
  };

  const extractUnNumbers = () => {
    const uniqueUnNumbers: string[] = [...new Set(
      goodsWatch
        .filter((item) => item?.dangerousGood_view !== undefined)
        .map((item) => item?.dangerousGood_view?.slice(0, 4) as string),
    )];

    form.setFieldValue(['addedServices', 'unCodes'], uniqueUnNumbers);
  };

  const dangGoodsObj = useMemo<ValueAddedService | null>(() => (
    addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dangerousGoods] ? (
      createDangerousGoodsObject(
        'Dangerous Goods PAX',
        'HE',
        addedServicesWatch?.dangerousGoodsQuantity || 1,
        '910',
        `${getPackageCountString(addedServicesWatch?.dangerousGoodsQuantity || 1)
        } DANGEROUS GOODS AS PER ASSOCIATED DGD - PAX`,
      )
    ) : null
  ), [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dangerousGoods],
    addedServicesWatch?.dangerousGoodsQuantity]);

  const limitedQuantityObj = useMemo<ValueAddedService | null>(() => (
    addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.limitedQuantity] ? (
      createDangerousGoodsObject(
        'Limited Quantity',
        'HL',
        addedServicesWatch?.limitedQuantity || 1,
        'A01',
        `${getPackageCountString(addedServicesWatch?.limitedQuantity || 1)
        } LIMITED QUANTITIES IN ROAD ONLY`,
      )
    ) : null
  ), [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.limitedQuantity], addedServicesWatch?.limitedQuantity]);

  const exceptedQuantityObj = useMemo<ValueAddedService | null>(() => (
    addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.exceptedQuantity] ? (
      createDangerousGoodsObject(
        'Excepted Quantity',
        'HH',
        addedServicesWatch?.exceptedQuantity || 1,
        'E01',
        `DANGEROUS GOODS IN EXCEPTED QUANTITIES,  ${getPackageCountString(addedServicesWatch?.exceptedQuantity || 1)
        } ${addedServicesWatch?.unCodes || ''}`,
        { unCodes: addedServicesWatch?.unCodes?.length ? addedServicesWatch?.unCodes : undefined },
      )
    ) : null
  ), [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.exceptedQuantity],
    addedServicesWatch?.exceptedQuantity, addedServicesWatch?.unCodes]);

  const dryIceObj = useMemo<ValueAddedService | null>(() => (
    addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dryIce] ? (
      createDangerousGoodsObject(
        'Dry Ice UN 1845',
        'HC',
        addedServicesWatch?.dryIce || 1,
        '901',
        `UN1845 DRY ICE, Net ${
          addedServicesWatch?.dryIceKg
            // eslint-disable-next-line max-len
            ? `weight ${addedServicesWatch?.dryIceKg} kg in ${addedServicesWatch?.dryIce} package${addedServicesWatch?.dryIce >= 2 ? 's' : ''}`
            : ''}`,
        { dryIceTotalNetWeight: addedServicesWatch?.dryIceKg },
      )
    ) : null
  ), [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dryIce],
    addedServicesWatch?.dryIce, addedServicesWatch?.dryIceKg]);

  const lithiumMetal969Obj = useMemo<ValueAddedService | null>(() => (
    addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal969] ? (
      createDangerousGoodsObject(
        'Lithium Metal PI1969',
        'HM',
        addedServicesWatch?.lithiumMetal969 || 1,
        '969',
        `${getPackageCountString(addedServicesWatch.lithiumMetal969 || 1)
        } LITHIUM METAL BATTERIES IN COMPLIANCE WITH SECTION II OF PI 969`,
      )
    ) : null
  ), [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal969], addedServicesWatch?.lithiumMetal969]);

  const lithiumMetal970Obj = useMemo<ValueAddedService | null>(() => (
    addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal970] ? (
      createDangerousGoodsObject(
        'Lithium Metal PI1970',
        'HW',
        addedServicesWatch?.lithiumMetal970 || 1,
        '970',
        `${getPackageCountString(addedServicesWatch?.lithiumMetal970 || 1)
        } LITHIUM METAL BATTERIES IN COMPLIANCE WITH SECTION II OF PI 970`,
      )
    ) : null
  ), [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal970], addedServicesWatch?.lithiumMetal970]);

  const lithiumIon966Obj = useMemo<ValueAddedService | null>(() => (
    addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon966] ? (
      createDangerousGoodsObject(
        'Lithium Ion PI1966',
        'HD',
        addedServicesWatch?.lithiumIon966 || 1,
        '966',
        `${getPackageCountString(addedServicesWatch?.lithiumIon966 || 1)
        } LITHIUM ION BATTERIES IN COMPLIANCE WITH SECTION II OF PI 966`,
      )
    ) : null
  ), [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon966], addedServicesWatch?.lithiumIon966]);

  const lithiumIon967Obj = useMemo<ValueAddedService | null>(() => (
    addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon967] ? (
      createDangerousGoodsObject(
        'Lithium Ion PI1967',
        'HV',
        addedServicesWatch?.lithiumIon967 || 1,
        '967',
        `${getPackageCountString(addedServicesWatch?.lithiumIon967 || 1)
        } LITHIUM ION BATTERIES IN COMPLIANCE WITH SECTION II OF PI 967`,
      )
    ) : null
  ), [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon967], addedServicesWatch?.lithiumIon967]);

  /** When some of addedService objects changed - generate formatted value and set in valueAddedServices field. */
  useEffect(() => {
    const generalServices: ValueAddedService[] = [];

    Object.keys(addedServicesCheckboxesWatch)?.forEach((key) => {
      /** If checkbox checked then push item object to array */
      if (addedServicesCheckboxesWatch?.[key as keyof CheckboxesServicesState]) {
        const service = serviceDefinitions[key as keyof CheckboxesServicesState];

        if (service) {
          generalServices.push(service);
        }
      }
    });

    const dangerousServices: ValueAddedService[] = [dangGoodsObj, limitedQuantityObj, exceptedQuantityObj,
      dryIceObj, lithiumMetal969Obj, lithiumMetal970Obj, lithiumIon966Obj, lithiumIon967Obj]
      .filter((item): item is ValueAddedService => item !== null);

    const mergedArray = [...generalServices, ...dangerousServices];

    form.setFieldValue('valueAddedServices', mergedArray);
  }, [addedServicesCheckboxesWatch,
    dangGoodsObj,
    limitedQuantityObj,
    exceptedQuantityObj,
    dryIceObj,
    lithiumMetal969Obj,
    lithiumMetal970Obj,
    lithiumIon966Obj,
    lithiumIon967Obj,
  ]);

  const {
    isDangerousGoodsVisible,
    isLimitedQuantityVisible,
    isExceptedQuantityVisible,
    isDryIceVisible,
    isLithiumVisible,
  } = useMemo(() => ({
    isDangerousGoodsVisible: accountNumberWatch === '155571976',
    isLimitedQuantityVisible: ['219733830', '966392290', '155571976'].includes(accountNumberWatch),
    isExceptedQuantityVisible: ['957226485', '155571976'].includes(accountNumberWatch),
    isDryIceVisible: ['957226485', '155571976'].includes(accountNumberWatch),
    isLithiumVisible: ['957226485', '155571976'].includes(accountNumberWatch),
  }), [accountNumberWatch]);

  /** >>>
   * Handling Account-auto-selection, filtering selects options.
   * */
  const { serviceTypeOptions, deliveryTermsOptions, isProductsDangerous } = useDeliveryOptions(
    form,
    shipperForm,
    consigneeForm,
    orderTypeForm,
    modeOfTransport,
    isUserAdmin,
    goodsWatch,
    deliveryService,
    serviceTypeWatch,
    deliveryTerms,
  );
  const countryShipper = Form.useWatch('country', shipperForm);
  const countryConsignee = Form.useWatch('country', consigneeForm);

  /** Additional validations. DHL Swiss Domestic -> only 1 package is max per order */
  const goodsPackagesCount = useMemo(() => (
    goodsWatch.reduce((total, current) => (
      total + (current?.quantity || 1)
    ), 0)
  ), [goodsWatch]);

  useEffect(() => {
    const callback = () => {
      if (!orderUpdate.loading && (goodsPackagesCount > 0 && deliveryService === DeliveryService.DHL)) {
        const isDomestic = countryShipper === 'Switzerland' && countryConsignee === 'Switzerland';
        // const goodsPackagesCount = goodsWatch.reduce((total, current) => (
        //   total + (current?.quantity || 1)
        // ), 0);
        const packagesCount = packagesWatch?.length || 0;
        const isSinglePackage = packagesCount === 1;

        const isWrongPackages = (goodsPackagesCount > 1 && !isSinglePackage) || packagesCount >= 2;

        if (isDomestic && isWrongPackages) {
          open({
            icon: <ExclamationCircleFilled />,
            title: 'DHL Shipping Restrictions',
            content: (
              <span>
                Domestic shipments within Switzerland are limited to one package per shipment.
                Please consolidate your items using the ‘Packages’ tab or remove one of the packages.
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Go to Products',
            onOk: () => setSearchParams({ tab: 'Products' }, { replace: true }),
          });
        }
      }
    };

    const timeoutId = setTimeout(callback, 300);

    return () => clearTimeout(timeoutId);
  }, [countryShipper, countryConsignee, deliveryService, goodsPackagesCount, packagesWatch?.length, orderUpdate.loading]);

  return (
    <FormWrapper
      formName={formName}
      className={clsx(commonStyles.wrapper, styles.delivery, { [styles.userGrid]: user?.role === 'user' })}
    >
      {contextHolder}
      <div className={clsx({ [styles.formVertical]: isRoleEnough(user?.role, 'admin') })}>
        {!isUserAdmin ? (
          <DeliveryCustomerFields formName={formName} deliveryTermsOptions={deliveryTermsOptions} />
        ) : null}

        <SelectDeliveryService
          label="Delivery service"
          name="deliveryService"
          rules={isUserAdmin ? [{ required: false, message: <div /> }] : fieldsRules}
          rightText={false}
          selectProps={{
            onChange: () => {
              form.setFieldValue('serviceType', null);
            },
            disabled: isFieldsDisabled,
          }}
        />

        {deliveryService?.toLowerCase() === DeliveryService.OTHER ? (
          <>
            <OrderInput
              label="Service name"
              name="serviceName"
              rules={[validationRules, { required: true, message: <div /> }]}
              {...defaultInputProps}
            />
            <OrderInput
              label="Tracking number"
              name="trackingNumber"
              rules={[validationRules, { required: true, message: <div /> }]}
              {...defaultInputProps}
            />
            <OrderInput
              label="Tracking link"
              name="trackingLink"
              rules={[validationRules, { required: true, message: <div /> }]}
              {...defaultInputProps}
            />

            {clientOrderData?.status !== 'Delivered' ? (
              <Button
                icon={<MailOutlined />}
                loading={orderSendTrackingLink.loading}
                type="default"
                onClick={(e) => {
                  e.preventDefault();
                  orderSave(e, () => {
                    orderSendTrackingLink.fetch();
                  });
                }}
              >
                {clientOrderData?.shippedDate && clientOrderData?.status === 'Shipped Out' ? (
                  'Resend Tracking link'
                ) : (
                  'Send Tracking link'
                )}
              </Button>
            ) : null}

            {clientOrderData?.shippedDate && clientOrderData?.status === 'Shipped Out' ? (
              <Button
                loading={orderMarkAsDelivered.loading}
                type="default"
                onClick={(e) => {
                  e.preventDefault();
                  orderMarkAsDelivered.fetch();
                }}
              >
                Mark as delivered
              </Button>
            ) : null}

            {trackingNumber && trackingLink ? (
              <div style={{ marginTop: '12px' }}>
                If you want to cancel this shipment, please do so in your personal account on the
                {' '}
                <a
                  href={`${trackingLink.toLowerCase().includes('https:')
                    ? `${trackingLink}${trackingNumber}`
                    : `https://${trackingLink}`}${trackingNumber}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  delivery service website
                </a>
              </div>
            ) : null}
          </>
        ) : null}

        {deliveryService?.toLowerCase() === DeliveryService.FEDEX ? (
          <>
            <SelectShipmentCreation
              label="Shipment creation"
              name="shipmentCreation"
              rules={[{ required: true, message: <div /> }]}
              {...customerShipmentSelectProps}
              {...defaultSelectProps}
            />

            <OrderSelect
              name="accountNumber"
              style={{ display: 'none' }}
            />

            {shipmentCreation !== 'Manually' ? (
              <SelectServiceType
                form={form}
                label="Service type"
                name="serviceType"
                rules={[{ required: true, message: <div /> }]}
                {...defaultSelectProps}
                selectProps={{
                  ...(defaultSelectProps?.selectProps || {}),
                  ...(serviceTypeOptions.length > 0 ? { options: serviceTypeOptions } : {}),
                }}
              />
            ) : null}

            {/* eslint-disable-next-line no-nested-ternary */}
            {shipmentCreation === 'Manually' ? (
              <OrderInput
                label="Tracking number"
                name="trackingNumber"
                rules={[validationRules, { required: true, message: <div /> }]}
                {...defaultInputProps}
              />
            ) : (initialState?.trackingNumber ? (
              <OrderInput
                inputProps={{
                  disabled: true,
                }}
                label="Tracking number"
                name="trackingNumber"
                rules={[validationRules, { required: true, message: <div /> }]}
                rightText={false}
              />
            ) : null)}

            {isUserAdmin ? (
              <>
                {createShipmentButton}
                {cancelShipmentButton}
              </>
            ) : null}

            {/* {initialState.trackingNumber ? (
              <div style={{ marginTop: 16 }}>
                If you want to cancel this shipment, please do so in your personal account on the
                {' '}
                <a
                  href={`https://www.fedex.com/shippingplus/en-de/shipment/view/${
                    initialState.trackingNumber.slice(0, 8)
                  }-${
                    initialState.trackingNumber.slice(-4)
                  }-0000-0000-000000000000?context=shipments-overview`}
                  target="_blank"
                  rel="noreferrer"
                >
                  delivery service website
                </a>
              </div>
            ) : null } */}
          </>
        ) : null}

        {deliveryService?.toLowerCase() === DeliveryService.SCHENKER ? (
          <>
            <SelectShipmentCreation
              label="Shipment creation"
              name="shipmentCreation"
              rules={[{ required: true, message: <div /> }]}
              {...customerShipmentSelectProps}
              {...defaultSelectProps}
            />
            <SelectServiceType
              form={form}
              label="Service type"
              name="serviceType"
              rules={[{ required: true, message: <div /> }]}
              {...defaultSelectProps}
            />

            {modeOfTransport === ModeOfTransport.IMDG ? (
              <>
                <SelectPort
                  label="Port discharge"
                  name="portDischarge"
                  rules={[{ required: true, message: <div /> }]}
                  {...defaultSelectProps}
                />
                <SelectPort
                  label="Port loading"
                  name="portLoading"
                  rules={[{ required: true, message: <div /> }]}
                  {...defaultSelectProps}
                />
              </>
            ) : null}

            {modeOfTransport === ModeOfTransport.IATA ? (
              <>
                <SelectAirport
                  label="Departure airport"
                  name="departureAirport"
                  rules={[{ required: true, message: <div /> }]}
                  {...defaultSelectProps}
                />
                <SelectAirport
                  label="Destination airport"
                  name="destinationAirport"
                  rules={[{ required: true, message: <div /> }]}
                  {...defaultSelectProps}
                />
              </>
            ) : null}

            {shipmentCreation === 'Manually' ? (
              <OrderInput
                label="Tracking number"
                name="trackingNumber"
                rules={[validationRules, { required: true, message: <div /> }]}
                {...defaultInputProps}
              />
            ) : null}

            {isUserAdmin ? (createShipmentButton) : null}

            {initialState?.trackingNumber ? (
              <div style={{ marginTop: 16 }}>
                If you want to cancel this shipment, please do so in your personal account on the
                {' '}
                <a href="https://www.dbschenker.com" target="_blank" rel="noreferrer">delivery service website</a>
              </div>
            ) : null}
          </>
        ) : null}

        {deliveryService?.toLowerCase() === DeliveryService.DHL ? (
          <>
            <SelectShipmentCreation
              label="Shipment creation"
              name="shipmentCreation"
              rules={[{ required: true, message: <div /> }]}
              {...customerShipmentSelectProps}
              {...defaultSelectProps}
            />

            <OrderSelect
              selectProps={{
                options: (orderAccountNumbersWithLabel.data || []).map(({ account, label }) => ({
                  label: `${account} - ${label}`,
                  value: account,
                })),
                disabled: !orderAccountNumbersWithLabel.data?.length
                  || isFieldsDisabled || user?.role === 'user', // TODO change to display: 'none' if role 'user'
                loading: orderAccountNumbersWithLabel.loading,
              }}
              label="Account"
              name="accountNumber"
              rules={[validationRules, { required: true, message: <div /> }]}
              rightText={false}
            />

            {shipmentCreation !== 'Manually' ? (
              <SelectServiceType
                form={form}
                label="Service type"
                name="serviceType"
                rules={[{ required: true, message: <div /> }]}
                {...defaultSelectProps}
                selectProps={{
                  ...(defaultSelectProps?.selectProps || {}),
                  ...(serviceTypeOptions.length > 0 ? { options: serviceTypeOptions } : {}),
                }}
              />
            ) : null}

            {/* eslint-disable-next-line no-nested-ternary */}
            {shipmentCreation === 'Manually' ? (
              <OrderInput
                label="Tracking number"
                name="trackingNumber"
                rules={[validationRules, { required: true, message: <div /> }]}
                {...defaultInputProps}
              />
            ) : (initialState?.trackingNumber ? (
              <OrderInput
                inputProps={{
                  disabled: true,
                }}
                label="Tracking number"
                name="trackingNumber"
                rules={[validationRules, { required: true, message: <div /> }]}
                rightText={false}
              />
            ) : null)}

            {shipmentCreation === 'Automatically' ? (
              <div className={styles.optionalServices}>
                <Row>
                  <h3 style={{ marginTop: '10px' }}>
                    <span>Optional services</span>
                  </h3>
                  <div style={{ marginBottom: '8px' }}>
                    <b>General</b>
                  </div>
                </Row>
                <Row style={{ display: 'flex' }}>
                  {([{ key: DHLOptionalServiceCodes.adultSignature, label: 'Adult Signature' },
                    { key: DHLOptionalServiceCodes.noSignatureRequired, label: 'No Signature required' },
                    { key: DHLOptionalServiceCodes.neutralDelivery, label: 'Neutral Delivery' },
                    { key: DHLOptionalServiceCodes.brokerNotification, label: 'Broker Notification' },
                    { key: DHLOptionalServiceCodes.directSignature, label: 'Direct Signature' },
                  ] as { key: keyof CheckboxesServicesState, label: string }[])
                    .map(({ key, label }) => (
                      <Col span={12} key={key}>
                        <FormItem
                          name={['addedServices', 'checkboxes', key]}
                          valuePropName="checked"
                          style={{ marginBottom: '8px' }}
                        >
                          <Checkbox disabled={isFieldsDisabled}>
                            {label}
                          </Checkbox>
                        </FormItem>
                      </Col>
                    ))}
                </Row>

                {isUserAdmin || isProductsDangerous ? (
                  <>
                    <Row>
                      <div style={{ marginTop: '10px' }}>
                        <b>Dangerous goods</b>
                      </div>
                      <div>
                        {accountNumberWatch ? (
                          <p style={{ paddingLeft: '218px' }}>No. of packages</p>
                        ) : (
                          <p style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
                            Select account number to pick optional services
                          </p>
                        )}
                      </div>
                    </Row>

                    {/** Optional services section \/ */}
                    {/** Saves hidden formatted-value for form submition */}
                    <Form.Item name="valueAddedServices" style={{ display: 'none' }}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Row className={styles.rowTwoGrid}>
                      {isDangerousGoodsVisible ? (
                        <>
                          <Col span={16}>
                            <FormItem
                              name={['addedServices', 'checkboxes', DHLOptionalServiceCodes.dangerousGoods]}
                              valuePropName="checked"
                            >
                              <Checkbox disabled={isFieldsDisabled}>
                                Dangerous goods (DGD-PAX)
                              </Checkbox>
                            </FormItem>
                          </Col>
                          <Col>
                            <OrderDeliveryDHLInput
                              name={['addedServices', 'dangerousGoodsQuantity']} // "dangerousGoods"
                              defaultValue={1}
                              precision={0}
                              disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dangerousGoods]
                                || isFieldsDisabled}
                            />
                          </Col>
                        </>
                      ) : null}

                      {isLimitedQuantityVisible ? (
                        <>
                          <Col span={16}>
                            <FormItem
                              name={['addedServices', 'checkboxes', DHLOptionalServiceCodes.limitedQuantity]}
                              valuePropName="checked"
                            >
                              <Checkbox disabled={isFieldsDisabled}>
                                Limited Quantity
                              </Checkbox>
                            </FormItem>
                          </Col>
                          <Col>
                            <OrderDeliveryDHLInput
                              name={['addedServices', 'limitedQuantity']}
                              defaultValue={1}
                              precision={0}
                              disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.limitedQuantity]
                                || isFieldsDisabled}
                            />
                          </Col>
                        </>
                      ) : null}
                    </Row>

                    {isExceptedQuantityVisible ? (
                      <Row className={styles.rowFourItems}>
                        <Col>
                          <FormItem
                            name={['addedServices', 'checkboxes', DHLOptionalServiceCodes.exceptedQuantity]}
                            valuePropName="checked"
                          >
                            <Checkbox disabled={isFieldsDisabled}>
                              Excepted Quantity
                            </Checkbox>
                          </FormItem>
                        </Col>
                        <Col>
                          <OrderDeliveryDHLInput
                            name={['addedServices', 'exceptedQuantity']}
                            defaultValue={1}
                            precision={0}
                            disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.exceptedQuantity]
                              || isFieldsDisabled}
                          />
                        </Col>
                        <Col>
                          <Form.Item
                            name={['addedServices', 'unCodes']} // "UnNumbers"
                            getValueProps={(value) => (
                              { value: Array.isArray(value) ? getFormattedUnNumbers(value) : value }
                            )}
                            normalize={(value) => value && handleUnNumbersChange(value)}
                          >
                            <Input
                              style={{ width: '160px' }}
                              defaultValue={[]}
                              placeholder="UN"
                              disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.exceptedQuantity]
                                || isFieldsDisabled}
                            />
                          </Form.Item>

                        </Col>
                        <Col>
                          <Form.Item>
                            <Button
                              icon={<ReloadOutlined />}
                              onClick={extractUnNumbers}
                              disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.exceptedQuantity]
                                || isFieldsDisabled}
                            >
                              Sync
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : null}

                    {isDryIceVisible ? (
                      <Row className={styles.rowFourItems}>
                        {/* TODO remove all Col's? */}
                        <Col>
                          <FormItem
                            name={['addedServices', 'checkboxes', DHLOptionalServiceCodes.dryIce]}
                            valuePropName="checked"
                          >
                            <Checkbox disabled={isFieldsDisabled}>
                              Dry Ice UN 1845
                            </Checkbox>
                          </FormItem>
                        </Col>
                        <Col>
                          <OrderDeliveryDHLInput
                            name={['addedServices', 'dryIce']}
                            defaultValue={1}
                            precision={0}
                            disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dryIce]
                              || isFieldsDisabled}
                          />
                        </Col>
                        <Col>
                          <Form.Item
                            name={['addedServices', 'dryIceKg']}
                            normalize={(value) => value && handleDryIceChange(value)}
                          >
                            <InputNumber
                              disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dryIce]
                                || isFieldsDisabled}
                              suffix="kg"
                              placeholder="0"
                              defaultValue={1}
                              min={0.001}
                              // precision={3}
                              controls={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button
                              icon={<ReloadOutlined />}
                              onClick={handleDryIceSum}
                              disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dryIce]
                                || isFieldsDisabled}
                            >
                              Sync
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : null}

                    {isLithiumVisible ? (
                      <Row style={{ marginBottom: '24px' }} className={styles.rowTwoGrid}>
                        <Col>
                          {([
                            { value: DHLOptionalServiceCodes.lithiumMetal969, label: 'Lithium Metal PI969 Section II' },
                            { value: DHLOptionalServiceCodes.lithiumIon966, label: 'Lithium Ion PI966 Section II' },
                            { value: DHLOptionalServiceCodes.lithiumIon967, label: 'Lithium Ion PI967 Section II' },
                            { value: DHLOptionalServiceCodes.lithiumMetal970, label: 'Lithium Metal PI970 Section II' },
                          ] as { value: keyof CheckboxesServicesState, label: string }[])
                            .map(({ value, label }) => (
                              <FormItem
                                key={value}
                                name={['addedServices', 'checkboxes', value]}
                                valuePropName="checked"
                              >
                                <Checkbox disabled={isFieldsDisabled}>
                                  {label}
                                </Checkbox>
                              </FormItem>
                            ))}
                        </Col>
                        <Col>
                          <OrderDeliveryDHLInput
                            name={['addedServices', 'lithiumMetal969']}
                            defaultValue={1}
                            precision={0}
                            disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal969]
                              || isFieldsDisabled}
                          />
                          <OrderDeliveryDHLInput
                            name={['addedServices', 'lithiumIon966']}
                            defaultValue={1}
                            precision={0}
                            disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon966]
                              || isFieldsDisabled}
                          />
                          <OrderDeliveryDHLInput
                            name={['addedServices', 'lithiumIon967']}
                            defaultValue={1}
                            precision={0}
                            disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon967]
                              || isFieldsDisabled}
                          />
                          <OrderDeliveryDHLInput
                            name={['addedServices', 'lithiumMetal970']}
                            defaultValue={1}
                            precision={0}
                            disabled={!addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal970]
                              || isFieldsDisabled}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </>
                ) : null}
              </div>
            ) : null}

            {isUserAdmin ? (
              <>
                {createShipmentButton}
                {cancelShipmentButton}
              </>
            ) : null}

            {/* {initialState?.trackingNumber ? (
              <div style={{ marginTop: 16 }}>
                If you want to cancel this shipment, please do so in your personal account on the
                {' '}
                <a
                  href={`https://www.dhl.com/ua-en/home/tracking.html?tracking-id=${initialState.trackingNumber}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  delivery service website
                </a>
              </div>
            ) : null } */}
          </>
        ) : null}

      </div>

      {isRoleEnough(user?.role, 'admin') ? (
        <Tracking
          title="Shipment tracking"
          trackingNumber={initialState?.trackingNumber}
          deliveryService={deliveryService}
          trackingLink={trackingLink}
          openTrackingSiteLinks={getOpenTrackingLink(deliveryService as DeliveryService)}
          className={styles.trackingShipment}
        />
      ) : null}
    </FormWrapper>
  );
}
