import { AnyObject } from '@triare/auth-redux';
import dayjs, { Dayjs } from 'dayjs';
import { CreateOrder, DangerousGoodsItem, ValueAddedService } from './types';
import {
  Currency,
  DeliveryTerms,
  InnerPackagingType,
  Mass,
  ModeOfTransport, OrderStatus,
  PackagingGroup,
  PackagingType,
} from './enums';
import { SelectOption } from '../../../../types/select';
import { Mode } from '../View/context';
import { DHLOptionalServiceCodes } from '../../../../enums/orderServices';
import { CompanyRole } from '../../../../hooks/api/company';
import { UserRole } from '../../../../store/auth';
import { isRoleEnough } from '../../../../enums/user';
import { DeliveryServiceType } from '../../../../hooks/api/tracking';
import { Invoice } from '../../../../hooks/api/invoices';
import { Offer } from '../../../../hooks/api/offers';

function getValueInObject(obj: any): string {
  if (typeof obj === 'object' && obj.label) {
    return obj.value;
  }

  return obj;
}

function getValueInObjectAll(obj: AnyObject) {
  const result: { [key: string]: string } = {};

  Object.keys(obj).forEach((key) => {
    result[key] = getValueInObject(obj[key]);
  });

  return result;
}

type Adapter = [string, string][];

export const clientData: Adapter = [
  ['', ''],
];

export const serverData: Adapter = clientData.map(([client, server]) => ([server, client]));

export interface ClientDataGeneral {
  company: string;
  modeOfTransport: ModeOfTransport;
  consignmentDate: dayjs.Dayjs | null;
  deliveryTerms: DeliveryTerms;
  insurance: boolean;
  insuranceValue: number;
  insuranceCurrency: Currency;
  waybillNo: number;
  notes: string;
  companyName: string;
  dataDate: dayjs.Dayjs | null;
  placeName: string;
  declarant: string;
  pickupDate: dayjs.Dayjs | null;
  pickupTime: [dayjs.Dayjs | null, dayjs.Dayjs | null],
  urgent: boolean;
  // fields for DHL / FedEx details
  preferredDeliveryService?: 'dhl' | 'fedex';
  billingNumber?: string;
  billingNumberCountryCode?: string;
  billingCode?: string;
  billingType?: string;
}

export interface ClientDataShipper {
  countryCode: string;
  stateCode: string;
  contactName: string;
  company: string;
  phone: string;
  email: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tin: string;
  registerNumber: string;
  reference: string;
  isAddressDifferent: boolean;
  details: {
    countryCode: string;
    stateCode: string;
    contactName: string;
    company: string;
    phone: string;
    email: string;
    country: string;
    address1: string;
    address2: string;
    postalCode: string;
    city: string;
    state: string;
    registerNumber?: string;
  };
}

export interface ClientDataImporter {
  countryCode: string;
  stateCode: string;
  contactName: string;
  company: string;
  phone: string;
  email: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tin: string;
  registerNumber: string;
  reference: string;
  isAddressDifferent: boolean;
  details: {
    countryCode: string;
    stateCode: string;
    contactName: string;
    company: string;
    phone: string;
    email: string;
    country: string;
    address1: string;
    address2: string;
    postalCode: string;
    city: string;
    state: string;
    registerNumber?: string;
  };
}

export interface MyUploadFile {
  file: null;
  fileList: {
    id: string;
    uid: string;
    name: string;
    status: string;
    loading: boolean;
    url: string;
  }[];
}

export interface ClientDataGood {
  id?: string;
  permit: string;
  permitNumber: string;
  permitDate: string | dayjs.Dayjs | null;
  saveProductToDatabase: boolean;
  dangerousGoods: boolean;
  dangerousGood_view?: string; // exist only on edit view
  innerPackaging: boolean;
  name: string;
  dangerousGood: {
    classificationCode: string;
    dangerousGoodId: string;
    name: string;
    labels: string;
    packingGroup: string;
    statusADR: string;
    statusIATA: string;
    statusIMDG: string;
    unNumber: string;
  };
  packagingGroup: PackagingGroup;
  hsCode: SelectOption | null;
  englishTechName: string;
  description: string;
  packaging: PackagingType | SelectOption | null;
  materialAndCode: string | SelectOption | null;
  isEmptyUncleaned: boolean | SelectOption;
  quantity: number;
  value: number;
  currency: Currency;
  net: number;
  massUnit: Mass;
  gross: number;
  length: number;
  width: number;
  height: number;
  volume: number;
  innerPackagingQuantity: number;
  innerPackagingType: InnerPackagingType | SelectOption | null;
  innerPackagingNetUnitaryQuantity: number;
  innerPackagingNetExposiveQuantity: number;
  environmentallyHazardous: boolean;
  physicalState: InnerPackagingType | SelectOption | null;
  density: number;
  pureMixSolution: number;
  pureMixSolutionPercentage: number;
  netExplosivePerPackage: number;
  flashPoint: string | null;
  // segregation: boolean;
  segregationGroup: number[] | SelectOption[];
  dualUse: boolean;
  exportLicense: boolean;
  // exportLicenseDocument: MyUploadFile | undefined;
  msdsDocument: MyUploadFile | undefined;
  temperatureLogger: boolean;
  realTimeMonitoring: boolean;
  specialTemperatureMode: boolean;
  temperatureMode: string;
  netWeightOfDryIce: number;
}

export interface ClientDataPackages {
  id: string;
  kind: PackageKindType; // Deprecated.
  length: number;
  width: number;
  height: number;
  quantity: number;
  weight: number;
  value: number;
  currency: CurrencyType;

  items: PackageItem[];
}

export type CurrencyType = 'EUR' | 'CHF' | 'USD' | string;

export enum EnumPackageKind {
  pallet = 'Pallet',
  ownPackaging = 'Own packaging',
}

export type PackageKindType = 'pallet' | 'ownPackaging';

export interface PackageItem {
  id?: string; // Not exist before creation of package
  quantity: number;
  goodsItem: ClientDataGood;
}

export interface ClientDataDocuments {
  transportDocuments: MyUploadFile | undefined,
  adrTransportDocument: boolean;
  loadChecklist: boolean;
  width: number;
  height: number;
  emergencyPhoneNumber: string;
  IATAMultimodalDangerous: boolean;
  IATAShowRedStripes: boolean;
  IATAShowBlueStripes: boolean;
  IMDGGoodsDeclaration: boolean;
  IMDGShowBlueStripes: boolean;
  additionalDocuments: MyUploadFile | undefined,
  proformaInvoices: MyUploadFile | undefined,
  invoiceNotes: string,
  packageMarking: boolean;
  marksForAllPackages: boolean;
  packageMarkingCustom: boolean;
  marksForAllPackagesSmall: boolean;
}

export interface ClientDataDelivery {
  deliveryService?: DeliveryServiceType;
  portDischarge?: string;
  portLoading?: string;
  departureAirport?: string;
  destinationAirport?: string;
  trackingNumber?: string;
  serviceName?: string;
  trackingLink?: string;
  orderNumber?: string;
  serviceType?: string;
  shipmentCreation?: string;
  accountNumber?: string;
  // DHL optional services data:
  addedServices?: AddedServicesProps; /** Used to hold form items state */
  valueAddedServices?: ValueAddedService[]; /** Formatted and prepared to submit data */
  /** Customer fields: */
  modeOfTransport: ModeOfTransport;
  consignmentDate: dayjs.Dayjs | null;
  pickupDate: dayjs.Dayjs | null;
  pickupTime: [dayjs.Dayjs | null, dayjs.Dayjs | null],
  deliveryTerms: DeliveryTerms;
  billingNumber?: string;
  insurance: boolean;
  insuranceValue: number;
  insuranceCurrency: Currency;
  emergencyPhoneNumber: string;
  notes: string;
}

export type ClientProductType = 'regular' | 'dangerous' | 'both';

/** Customer OrderType tab data */
export interface ClientDataOrderType {
  productType: ClientProductType;
  isTemperatureControl: boolean;
  isAssistanceNeeded: boolean;
  assistanceNote?: string;
  urgent?: boolean;
  isOfferNeeded?: boolean;
}

export interface CheckboxesServicesState {
  SD: boolean; // adultSignature
  NN: boolean; // neutralDelivery
  SF: boolean; // directSignature
  SX: boolean; // noSignatureRequired
  WG: boolean; // brokerNotification
  HE: boolean; // dangerousGoods
  HL: boolean; // limitedQuantity
  HH: boolean; // exceptedQuantity
  HC: boolean; // dryIce
  HM: boolean; // lithiumMetal969
  HD: boolean; // lithiumIon966
  HV: boolean; // lithiumIon967
  HW: boolean; // lithiumMetal970
}

export interface AddedServicesProps {
  checkboxes: CheckboxesServicesState;
  dangerousGoodsQuantity: number;
  dryIce: number;
  dryIceKg: number;
  exceptedQuantity: number;
  limitedQuantity: number;
  lithiumIon967: number;
  lithiumIon966: number;
  lithiumMetal969: number;
  lithiumMetal970: number;
  unCodes: string[];
}

export interface ClientData {
  id: string;
  conId: string;
  status: OrderStatus;
  shippedDate: string;
  deliveredDate: string;
  company: GetServerDataCompany;
  general: ClientDataGeneral;
  shipper: ClientDataShipper;
  importer: ClientDataImporter;
  goods: {
    goods: ClientDataGood[];
  };
  packages: {
    packages: ClientDataPackages[];
    packageSummary: string | null; // Shipment summary
  },
  documents: ClientDataDocuments;
  delivery: ClientDataDelivery;
  /** Customer forms: */
  orderType: ClientDataOrderType;

  offers: Offer[];
  invoices: Invoice[];
}

type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export type ServerData = DeepPartial<CreateOrder>;

/** Function which used to prepare data for API request (from Client to API) */
export function getServerData(data: ClientData, userRole?: UserRole): ServerData {
  const isUserAdmin = isRoleEnough(userRole, 'admin');
  const {
    general,
    shipper,
    importer,
    goods: { goods },
    packages: { packages, packageSummary },
    documents,
    delivery,
    orderType,
  } = data;

  const pickupDate = general.pickupDate || delivery.pickupDate || null;
  let pickupDateFrom;
  let pickupDateTo;

  if (
    (general?.pickupTime?.[0] && general?.pickupTime?.[1])
    || (delivery?.pickupTime?.[0] && delivery?.pickupTime?.[1])
  ) {
    const pickupTimeFrom = delivery?.pickupTime?.[0] || general?.pickupTime?.[0];
    const pickupTimeTo = delivery?.pickupTime?.[1] || general?.pickupTime?.[1];

    if (pickupTimeFrom) {
      pickupDateFrom = (pickupDate || dayjs()).clone()
        .set('hour', pickupTimeFrom.get('hour'))
        .set('minute', pickupTimeFrom.get('minute'));
    }
    if (pickupTimeTo) {
      pickupDateTo = (pickupDate || dayjs()).clone()
        .set('hour', pickupTimeTo.get('hour'))
        .set('minute', pickupTimeTo.get('minute'));
    }
  }

  /** Fields which displays as select, and clears with 'null' value, and exist only on admin form. */
  const onlyAdminFields: ServerData = {
    // consignmentDate: (general.consignmentDate || dayjs())?.toISOString() || null,
    dataDate: (general.dataDate || dayjs())?.toISOString() || null,
  };

  /** If ProductType is not 'dangerous' or 'both', and some products is marked as 'dangerous', match it */
  const preparedProductType = (goods || [])
    .some((item) => item?.dangerousGoods) && !['dangerous', 'both'].includes(orderType?.productType || '')
    ? 'dangerous'
    : orderType.productType;

  const result: ServerData = {
    // >> Order Type
    productType: preparedProductType, // orderType.productType
    isTemperatureControl: orderType.isTemperatureControl,
    isAssistanceNeeded: orderType.isAssistanceNeeded,
    assistanceNote: orderType.assistanceNote,
    isOfferNeeded: orderType.isOfferNeeded,
    // >> General
    company: general.company,
    modeOfTransport: general.modeOfTransport || delivery.modeOfTransport || null,
    /** >>> Hiding fields from general tab if user not admin. */
    ...(isUserAdmin ? onlyAdminFields : {}),
    /** <<< */
    consignmentDate: (general.consignmentDate || delivery.consignmentDate || dayjs())?.toISOString() || null, // New
    pickupDate: pickupDate ? pickupDate.toISOString() : null,
    deliveryTerms: general.deliveryTerms || delivery.deliveryTerms || null,
    insurance: general.insurance || delivery.insurance || null,
    insuranceValue: general.insuranceValue || delivery.insuranceValue,
    insuranceCurrency: general.insuranceCurrency || delivery.insuranceCurrency || 'CHF',
    waybillNo: general.waybillNo,
    notes: general.notes || delivery.notes,
    companyName: general.companyName,
    placeName: general.placeName,
    declarant: general.declarant,
    // fields for DHL / FedEx details
    preferredDeliveryService: general.preferredDeliveryService,
    billingNumber: general.billingNumber || delivery.billingNumber,
    billingNumberCountryCode: general.billingNumberCountryCode || undefined,
    billingCode: general.billingCode || undefined,
    billingType: general.billingType || undefined,
    // << General

    // >> Shipper details
    shipper: {
      countryCode: shipper?.countryCode,
      stateCode: shipper?.stateCode,

      contactName: shipper?.contactName,
      company: shipper?.company || null,
      phone: shipper?.phone,
      email: shipper?.email,
      country: shipper?.country,
      address1: shipper?.address1,
      address2: shipper?.address2,
      postalCode: shipper?.postalCode,
      city: shipper?.city,
      state: shipper?.state,
      tin: shipper?.tin,
      registerNumber: shipper?.registerNumber,
      reference: shipper?.reference,

      // Pickup details (from general tab)
      /** Initial values for pickup time should be empty. Because when time is set - order pickup requested */
      pickupDateFrom: pickupDateFrom ? dayjs(pickupDateFrom).utc(true)?.toISOString() : null, // General
      pickupDateTo: pickupDateTo ? dayjs(pickupDateTo).utc(true)?.toISOString() : null, // General
      urgent: orderType?.urgent || general.urgent || false, // General

      isPickupAddressDifferent: shipper?.isAddressDifferent || false, // General
      pickupCountryCode: shipper?.details?.countryCode,
      pickupStateCode: shipper?.details?.stateCode,
      pickupCompany: shipper?.details?.company,
      pickupContactName: shipper?.details?.contactName,
      pickupPhone: shipper?.details?.phone,
      pickupEmail: shipper?.details?.email,
      pickupCountry: shipper?.details?.country,
      pickupAddress1: shipper?.details?.address1,
      pickupAddress2: shipper?.details?.address2,
      pickupPostalCode: shipper?.details?.postalCode,
      pickupCity: shipper?.details?.city,
      pickupState: shipper?.details?.state,
      pickupRegisterNumber: shipper?.details?.registerNumber || null,
    },
    // << Shipper details

    // >> Importer details
    importer: {
      countryCode: importer?.countryCode,
      stateCode: importer?.stateCode,

      contactName: importer?.contactName,
      company: importer?.company || null,
      phone: importer?.phone,
      email: importer?.email,
      country: importer?.country,
      address1: importer?.address1,
      address2: importer?.address2,
      postalCode: importer?.postalCode,
      city: importer?.city,
      state: importer?.state,
      tin: importer?.tin,
      registerNumber: importer?.registerNumber,
      reference: importer?.reference,

      isDeliveryAddressDifferent: importer?.isAddressDifferent || false, // General
      deliveryCompany: importer?.details?.company,
      deliveryContactName: importer?.details?.contactName,
      deliveryCountryCode: importer?.details?.countryCode,
      deliveryStateCode: importer?.details?.stateCode,
      deliveryPhone: importer?.details?.phone,
      deliveryEmail: importer?.details?.email,
      deliveryCountry: importer?.details?.country,
      deliveryAddress1: importer?.details?.address1,
      deliveryAddress2: importer?.details?.address2,
      deliveryPostalCode: importer?.details?.postalCode,
      deliveryCity: importer?.details?.city,
      deliveryState: importer?.details?.state,
      deliveryRegisterNumber: importer?.details?.registerNumber || null,
    },
    // << Importer details

    // >> List of goods
    goods: [],
    // << List of goods
    packages: (packages || []).length > 0 && packages?.[0]?.width ? packages?.map((packageItem) => ({
      ...packageItem,
      createdAt: undefined,
      updatedAt: undefined,
      volume: undefined,
      items: (packageItem?.items || []).map((i) => ({
        id: i?.id,
        quantity: i?.quantity,
        goodsItem: { id: i?.goodsItem.id },
      })),
    })) : [],
    packageSummary: packageSummary || null,
    // << List of packages

    transportDocuments: documents?.transportDocuments?.fileList?.filter((
      { id: fileId }: AnyObject,
    ) => fileId)?.map(({ id }) => id),
    additionalDocuments: documents?.additionalDocuments?.fileList?.filter((
      { id: fileId }: AnyObject,
    ) => fileId)?.map(({ id }) => id),
    proformaInvoice: documents?.proformaInvoices?.fileList?.filter((
      { id: fileId }: AnyObject,
    ) => fileId)?.map(({ id }) => id)[0] || undefined,

    adrTransportDocument: documents?.adrTransportDocument || false,
    loadChecklist: documents?.loadChecklist || false,
    width: documents?.width,
    height: documents?.height,
    emergencyPhoneNumber: documents?.emergencyPhoneNumber || delivery?.emergencyPhoneNumber,
    packageMarking: documents?.packageMarking || false,
    marksForAllPackages: documents?.marksForAllPackages || false,
    packageMarkingCustom: documents?.packageMarkingCustom || false,
    marksForAllPackagesSmall: documents?.marksForAllPackagesSmall || false,
    IATAMultimodalDangerous: documents?.IATAMultimodalDangerous || false,
    IATAShowRedStripes: documents?.IATAShowRedStripes || false,
    IATAShowBlueStripes: documents?.IATAShowBlueStripes || false,
    IMDGGoodsDeclaration: documents?.IMDGGoodsDeclaration || false,
    IMDGShowBlueStripes: documents?.IMDGShowBlueStripes || false,
    invoiceNotes: documents?.invoiceNotes,
    // >> Delivery
    deliveryService: delivery?.deliveryService || null,
    serviceName: delivery?.serviceName || null,
    trackingLink: delivery?.trackingLink || null,
    trackingNumber: delivery?.trackingNumber || null,
    orderNumber: delivery?.orderNumber, // Should not be sent
    shipmentCreation: userRole === 'user'
      ? delivery?.shipmentCreation || 'Automatically'
      : delivery?.shipmentCreation || null,
    accountNumber: delivery?.accountNumber || null,
    serviceType: delivery?.serviceType || null,
    portDischarge: delivery?.portDischarge || null,
    portLoading: delivery?.portLoading || null,
    departureAirport: delivery?.departureAirport || null,
    destinationAirport: delivery?.destinationAirport || null,

    // DHL optional services:
    valueAddedServices: delivery?.valueAddedServices || [],
  };

  if (goods && goods?.length > 0) {
    goods?.forEach((good) => {
      result.goods?.push({
        id: good?.id,
        permit: good?.permit?.toLowerCase(),
        permitNumber: good?.permitNumber,
        permitDate: good?.permitDate || null,
        saveProductToDatabase: good?.saveProductToDatabase,
        dangerousGoods: good?.dangerousGoods || false,
        innerPackaging: good?.innerPackaging || false,
        name: good?.name,
        dangerousGood: typeof good?.dangerousGood === 'object'
          ? good?.dangerousGood?.dangerousGoodId || 0 : good?.dangerousGood,
        packagingGroup: good?.packagingGroup,
        hsCode: (good?.hsCode
          && typeof good?.hsCode === 'object'
          && good?.hsCode.value
          ? good?.hsCode.value
          : good?.hsCode) || null,
        englishTechName: good?.englishTechName,
        description: good?.description,
        packaging: (good?.packaging
          && typeof good?.packaging === 'object'
          && good?.packaging.value
          ? good?.packaging.value
          : good?.packaging) || null,
        materialAndCode: good?.materialAndCode
          && typeof good?.materialAndCode === 'object'
          && good?.materialAndCode.value
          ? good?.materialAndCode.value
          : good?.materialAndCode || '', // null
        isEmptyUncleaned: !!(typeof good?.isEmptyUncleaned === 'object'
          ? good?.isEmptyUncleaned.value : good?.isEmptyUncleaned),
        quantity: good?.quantity || null,
        value: good?.value,
        currency: good?.currency,
        net: good?.isEmptyUncleaned ? 0 : good?.net,
        massUnit: good?.massUnit,
        gross: good?.gross,
        length: good?.length,
        width: good?.width,
        height: good?.height,
        volume: good?.volume,
        innerPackagingQuantity: good?.innerPackagingQuantity || null,
        innerPackagingType: (good.innerPackagingType
        && typeof good?.innerPackagingType === 'object'
        && good.innerPackagingType.value
          ? good?.innerPackagingType.value
          : good?.innerPackagingType) || null,
        innerPackagingNetUnitaryQuantity: good?.innerPackagingNetUnitaryQuantity,
        innerPackagingNetExposiveQuantity: good?.innerPackagingNetExposiveQuantity,
        environmentallyHazardous: good?.environmentallyHazardous || null,
        physicalState: (good.physicalState
        && typeof good?.physicalState === 'object'
        && good.physicalState.value
          ? good?.physicalState.value
          : good?.physicalState) || null,
        density: good?.density,
        pureMixSolution: good?.pureMixSolution,
        pureMixSolutionPercentage: good?.pureMixSolutionPercentage,
        netExplosivePerPackage: good?.netExplosivePerPackage,
        flashPoint: good?.flashPoint === '' ? null : good?.flashPoint,
        // segregation: good?.segregation || false,
        segregationGroup: good?.segregationGroup?.map((item): number => {
          if (typeof item === 'object' && item.value) {
            return +item.value;
          }

          return item as number;
        }) || null,
        dualUse: good?.dualUse || false,
        exportLicense: good?.exportLicense || false,

        // exportLicenseDocument: (good?.exportLicenseDocument as AnyObject)?.fileList?.filter((
        //   { id: fileId }: AnyObject,
        // ) => fileId)
        //   .map((
        //     { id }: { id: string },
        //   ) => id.toString())[0] || null,
        msdsDocument: (good?.msdsDocument || {})?.fileList?.filter((
          { id: fileId }: AnyObject,
        ) => fileId)?.map(({ id }: { id: string }) => (id))[0] || undefined,

        temperatureLogger: good?.temperatureLogger || false,
        realTimeMonitoring: good?.realTimeMonitoring || false,
        specialTemperatureMode: good?.specialTemperatureMode || false,
        temperatureMode: good?.temperatureMode,
        netWeightOfDryIce: good?.netWeightOfDryIce,
      });
    });
  }

  return result;
}

export interface GetServerDataCompany {
  id: string;
  companyName: string;
  participantType: string;
  contactName: string;
  phone: string;
  email: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tin: string;
  registerNumber: string;
  createdAt: string; // Date
  paymentDays: number;
  role: CompanyRole;
}

export interface GetServerDataShipper {
  id: string;
  contactName: string;
  company: string;
  phone: string;
  email: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tin: string;
  registerNumber: string;
  reference: string;
  pickupContactName: string;
  pickupCompany: string;
  pickupPhone: string;
  pickupEmail: string;
  pickupCountry: string;
  pickupAddress1: string;
  pickupAddress2: string;
  pickupPostalCode: string;
  pickupCity: string;
  pickupState: string;
  pickupRegisterNumber?: string;
  shippingType: string;
  pickupDateFrom: string;
  pickupDateTo: string;
  urgent: boolean;
  isPickupAddressDifferent: boolean;
  countryCode: string;
  stateCode: string;
  pickupCountryCode: string;
  pickupStateCode: string;
}

export interface GetServerDataImporter {
  id: string;
  contactName: string;
  company: string;
  phone: string;
  email: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tin: string;
  registerNumber: string;
  reference: string;
  deliveryContactName: string;
  deliveryCompany: string;
  deliveryPhone: string;
  deliveryEmail: string;
  deliveryCountry: string;
  deliveryAddress1: string;
  deliveryAddress2: string;
  deliveryPostalCode: string;
  deliveryCity: string;
  deliveryState: string;
  deliveryRegisterNumber?: string;
  isDeliveryAddressDifferent: boolean;
  countryCode: string;
  stateCode: string;
  deliveryCountryCode: string;
  deliveryStateCode: string;
}

export interface GetServerDataOrderType {
  productType: ClientProductType;
  isTemperatureControl: boolean;
  isAssistanceNeeded: boolean;
  assistanceNote?: string;
  // 'urgent' is in (shipper -> urgent)
  isOfferNeeded?: boolean;
}

interface GetServerSimpleData extends GetServerDataOrderType {
  id: string;
  conId: string;
  shipmentCreation: string;
  accountNumber: string;
  serviceType: string;
  portDischarge: string;
  portLoading: string;
  departureAirport: string;
  destinationAirport: string;
  orderNumber: string;
  consignmentDate: dayjs.Dayjs | null; // Date
  dataDate: string; // Date
  waybillNo: number;
  companyName: string;
  adrTransportDocument: boolean;
  loadChecklist: boolean;
  packageMarking: boolean;
  marksForAllPackages: boolean;
  packageMarkingCustom: boolean;
  width: number;
  height: number;
  emergencyPhoneNumber: string;
  placeName: string;
  declarant: string;
  deliveryTerms: DeliveryTerms;
  modeOfTransport: ModeOfTransport;
  insurance: boolean;
  pickupDate: string;
  insuranceValue: number;
  insuranceCurrency: Currency;
  status: OrderStatus;
  shippedDate: string;
  deliveredDate: string;
  IATAMultimodalDangerous: boolean;
  IATAShowBlueStripes: boolean; // removed
  IATAShowRedStripes: boolean; // removed
  IMDGGoodsDeclaration: boolean;
  IMDGShowBlueStripes: boolean; // removed
  marksForAllPackagesSmall: boolean;
  notes: string;
  createdAt: string; // Date
  transportDocuments: any[];
  additionalDocuments: any[];
  proformaInvoice: any | null;
  invoiceNotes: string;
  deliveryService: DeliveryServiceType // string;
  trackingNumber?: string;
  serviceName?: string;
  trackingLink?: string;
  // fields for DHL / FedEx details
  preferredDeliveryService?: 'dhl' | 'fedex';
  billingNumber?: string;
  billingNumberCountryCode?: string;
  billingCode?: string;
  billingType?: string;

  packageSummary: string | null;
  valueAddedServices?: ValueAddedService[];

  offers: Offer[];
  invoices: Invoice[];
}

export interface GetServerData extends GetServerSimpleData {
  company: GetServerDataCompany;
  shipper: GetServerDataShipper;
  importer: GetServerDataImporter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  goods: any[];
  packages: ClientDataPackages[];
}

function prepareAddedServiceItem<T>(
  valueAddedServices: ValueAddedService[] | undefined,
  serviceKey: typeof DHLOptionalServiceCodes[keyof typeof DHLOptionalServiceCodes],
  innerKey?: keyof DangerousGoodsItem,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any = 1,
): T {
  return (
    ((valueAddedServices || [])
      .find((item) => item.serviceCode === serviceKey)
      ?.dangerousGoods?.[0]?.[innerKey || 'quantity'] as T | number) || defaultValue
  );
}

/** Used to prepare data for client-form (from API to Client) */
export function getClientData(response: GetServerData, mode: Mode): ClientData {
  const {
    id: orderId,
    conId,
    status,
    orderNumber,
    accountNumber,
    serviceType,
    portDischarge,
    portLoading,
    departureAirport,
    destinationAirport,
    shipmentCreation,
    company,
    shipper,
    importer,
    goods,
    packages,
    packageSummary,
    modeOfTransport,
    consignmentDate,
    deliveryTerms,
    insurance,
    insuranceValue,
    insuranceCurrency,
    waybillNo,
    notes,
    dataDate,
    adrTransportDocument,
    loadChecklist,
    width,
    height,
    emergencyPhoneNumber,
    packageMarking,
    marksForAllPackages,
    packageMarkingCustom,
    invoiceNotes,
    marksForAllPackagesSmall,
    companyName,
    placeName,
    declarant,
    transportDocuments,
    additionalDocuments,
    proformaInvoice,
    trackingNumber,
    deliveryService,
    serviceName,
    trackingLink,
    shippedDate,
    deliveredDate,
    IATAMultimodalDangerous,
    IATAShowBlueStripes,
    IATAShowRedStripes,
    IMDGGoodsDeclaration,
    IMDGShowBlueStripes,
    // fields for DHL / FedEx details
    preferredDeliveryService,
    billingNumber,
    billingNumberCountryCode,
    billingCode,
    billingType,
    valueAddedServices,
    // Client order-type tab fields
    productType,
    isTemperatureControl,
    isAssistanceNeeded,
    assistanceNote,
    isOfferNeeded,
    invoices,
    offers,
  } = response;

  const preparedPickupDate = shipper?.pickupDateFrom ? dayjs(shipper?.pickupDateFrom).utc() : null;
  // if do undefined, then on update it works wrong, and utc date dont work.
  const preparedPickupTime: [Dayjs | null, Dayjs | null] = shipper?.pickupDateFrom ? [
    // eslint-disable-next-line no-nested-ternary
    shipper?.pickupDateFrom
      ? dayjs(shipper?.pickupDateFrom).utc()
      : (mode === 'view' ? null
        : dayjs()?.utc().set('minute', 0).set('second', 0)
          .add(dayjs().utcOffset(), 'minute')),
    // eslint-disable-next-line no-nested-ternary
    shipper?.pickupDateTo
      ? dayjs(shipper?.pickupDateTo).utc()
      : (mode === 'view' ? null
        : dayjs()?.utc().set('minutes', 0).set('second', 0)
          .add(dayjs().utcOffset(), 'minute')
          .add(2, 'hour')), // For pickupDateTo need to add 2 hours gap
  ] : [null, null];

  const data: ClientData = {
    id: orderId,
    conId,
    status,
    company,
    shippedDate,
    deliveredDate,
    invoices,
    offers,
    general: {
      company: company?.id,
      modeOfTransport,
      consignmentDate: consignmentDate ? dayjs(consignmentDate).utc() : null,
      deliveryTerms,
      insurance,
      insuranceValue,
      insuranceCurrency: insuranceCurrency || 'CHF',
      waybillNo,
      notes,
      dataDate: dataDate ? dayjs(dataDate).utc() : null,
      companyName,
      placeName,
      declarant,
      pickupDate: preparedPickupDate, // undefined
      pickupTime: preparedPickupTime,
      urgent: shipper?.urgent,
      preferredDeliveryService,
      billingNumber,
      billingNumberCountryCode,
      billingCode,
      billingType,
    },
    shipper: {
      countryCode: shipper?.countryCode,
      stateCode: shipper?.stateCode,

      contactName: shipper?.contactName,
      company: shipper?.company,
      phone: shipper?.phone,
      email: shipper?.email,
      country: shipper?.country,
      address1: shipper?.address1,
      address2: shipper?.address2,
      postalCode: shipper?.postalCode,
      city: shipper?.city,
      state: shipper?.state,
      tin: shipper?.tin,
      registerNumber: shipper?.registerNumber,
      reference: shipper?.reference,
      isAddressDifferent: shipper?.isPickupAddressDifferent,
      details: {
        countryCode: shipper?.pickupCountryCode,
        stateCode: shipper?.pickupStateCode,
        contactName: shipper?.pickupContactName,
        company: shipper?.pickupCompany,
        phone: shipper?.pickupPhone,
        email: shipper?.pickupEmail,
        country: shipper?.pickupCountry,
        address1: shipper?.pickupAddress1,
        address2: shipper?.pickupAddress2,
        postalCode: shipper?.pickupPostalCode,
        city: shipper?.pickupCity,
        state: shipper?.pickupState,
        registerNumber: shipper?.pickupRegisterNumber,
      },
    },
    importer: {
      countryCode: importer?.countryCode,
      stateCode: importer?.stateCode,

      contactName: importer?.contactName,
      company: importer?.company,
      phone: importer?.phone,
      email: importer?.email,
      country: importer?.country,
      address1: importer?.address1,
      address2: importer?.address2,
      postalCode: importer?.postalCode,
      city: importer?.city,
      state: importer?.state,
      tin: importer?.tin,
      registerNumber: importer?.registerNumber,
      reference: importer?.reference,
      isAddressDifferent: importer?.isDeliveryAddressDifferent,
      details: {
        countryCode: importer?.deliveryCountryCode,
        stateCode: importer?.deliveryStateCode,
        contactName: importer?.deliveryContactName,
        company: importer?.deliveryCompany,
        phone: importer?.deliveryPhone,
        email: importer?.deliveryEmail,
        country: importer?.deliveryCountry,
        address1: importer?.deliveryAddress1,
        address2: importer?.deliveryAddress2,
        postalCode: importer?.deliveryPostalCode,
        city: importer?.deliveryCity,
        state: importer?.deliveryState,
        registerNumber: importer?.deliveryRegisterNumber,
      },
    },
    goods: {
      goods: goods?.map((good) => ({
        id: good?.id,
        permit: good?.permit?.toLowerCase(),
        permitNumber: good?.permitNumber,
        permitDate: good?.permitDate ? dayjs(good?.permitDate).utc() : null,
        saveProductToDatabase: good?.saveProductToDatabase,
        dangerousGoods: good?.dangerousGoods,
        innerPackaging: good?.innerPackaging,
        name: good?.name,
        dangerousGood: good?.dangerousGood,
        packagingGroup: good?.packagingGroup,
        hsCode: good?.hsCode ? {
          value: good?.hsCode,
          label: good?.hsCode,
        } : null,
        englishTechName: good?.englishTechName,
        description: good?.description,
        packaging: good?.packaging ? {
          value: good?.packaging?.packageId,
          label: good?.packaging?.name,
        } : null,
        materialAndCode: good?.materialAndCode ? {
          value: good?.materialAndCode?.packageHomologationId,
          label: `${good?.materialAndCode?.name} - ${good?.materialAndCode?.code}`,
        } : null,
        isEmptyUncleaned: !!good?.isEmptyUncleaned,
        quantity: good?.quantity,
        value: good?.value,
        currency: good?.currency,
        net: good?.net,
        massUnit: good?.massUnit,
        gross: good?.gross,
        length: good?.length,
        width: good?.width,
        height: good?.height,
        volume: good?.volume,
        innerPackagingQuantity: good?.innerPackagingQuantity,
        innerPackagingType: good?.innerPackagingType ? {
          value: good?.innerPackagingType?.innerPackageMaterialId,
          label: good?.innerPackagingType?.name,
        } : null,
        innerPackagingNetUnitaryQuantity: good?.innerPackagingNetUnitaryQuantity,
        innerPackagingNetExposiveQuantity: good?.innerPackagingNetExposiveQuantity,
        environmentallyHazardous: good?.environmentallyHazardous,
        physicalState: good?.physicalState ? {
          value: good?.physicalState,
          label: good?.physicalState,
        } : null,
        density: good?.density,
        pureMixSolution: good?.pureMixSolution,
        pureMixSolutionPercentage: good?.pureMixSolutionPercentage,
        netExplosivePerPackage: good?.netExplosivePerPackage,
        flashPoint: good?.flashPoint === '' ? null : good?.flashPoint,
        // segregation: good?.segregation,
        segregationGroup: good?.segregationGroup?.map(
          ({ name, imdgSegregationGroupId }: { name: string; imdgSegregationGroupId: string }) => ({
            label: name,
            value: imdgSegregationGroupId,
          }),
        ),
        dualUse: good?.dualUse || false,
        exportLicense: good?.exportLicense || false,
        // exportLicenseDocument:
        //   good?.exportLicenseDocument ? {
        //     file: null,
        //     fileList: [
        //       {
        //         id: good?.exportLicenseDocument.id,
        //         uid: good?.exportLicenseDocument.id,
        //         name: good?.exportLicenseDocument.originalName,
        //         status: 'done',
        //         loading: false,
        //         url: good?.exportLicenseDocument.location,
        //       },
        //     ],
        //   } : undefined,
        msdsDocument:
          good?.msdsDocument ? {
            file: null,
            fileList: [
              {
                id: good?.msdsDocument.id,
                uid: good?.msdsDocument.id,
                name: good?.msdsDocument.originalName,
                status: 'done',
                loading: false,
                url: good?.msdsDocument.location,
              },
            ],
          } : undefined,
        temperatureLogger: good?.temperatureLogger,
        realTimeMonitoring: good?.realTimeMonitoring,
        specialTemperatureMode: !!good?.specialTemperatureMode,
        temperatureMode: good?.temperatureMode,
        netWeightOfDryIce: good?.netWeightOfDryIce,
      })),
    },
    packages: {
      packages,
      packageSummary,
    },
    documents: {
      transportDocuments: transportDocuments ? {
        file: null,
        fileList: transportDocuments?.length ? transportDocuments.map((transportDocument) => ({
          id: transportDocument?.id,
          uid: transportDocument?.id,
          name: transportDocument?.originalName,
          status: 'done',
          loading: false,
          url: transportDocument?.location,
        })) : [],
      } : undefined,
      additionalDocuments:
        additionalDocuments ? {
          file: null,
          fileList: additionalDocuments?.length ? additionalDocuments.map((additionalDocument) => ({
            id: additionalDocument?.id,
            uid: additionalDocument?.id,
            name: additionalDocument?.originalName,
            status: 'done',
            loading: false,
            url: additionalDocument?.location,
          })) : [],
        } : undefined,
      proformaInvoices:
        proformaInvoice ? {
          file: null,
          fileList: [
            {
              id: proformaInvoice?.id,
              uid: proformaInvoice?.id,
              name: proformaInvoice?.originalName,
              status: 'done',
              loading: false,
              url: proformaInvoice?.location,
            },
          ],
        } : undefined,
      adrTransportDocument,
      loadChecklist,
      width,
      height,
      IATAMultimodalDangerous,
      IATAShowBlueStripes,
      IATAShowRedStripes,
      IMDGGoodsDeclaration,
      IMDGShowBlueStripes,
      packageMarking,
      marksForAllPackages,
      packageMarkingCustom,
      marksForAllPackagesSmall,
      invoiceNotes,
      emergencyPhoneNumber,
    },
    delivery: {
      orderNumber,
      accountNumber,
      serviceType,
      portDischarge,
      portLoading,
      departureAirport,
      destinationAirport,
      shipmentCreation: shipmentCreation || 'Automatically',
      deliveryService,
      trackingNumber,
      serviceName,
      trackingLink,
      addedServices: {
        /** Making checkbox checked from selected service codes. (Checkboxes names same as serviceCode) */
        checkboxes: (valueAddedServices || [])
          ?.reduce((acc, service) => {
            const key = service.serviceCode as typeof DHLOptionalServiceCodes[keyof typeof DHLOptionalServiceCodes];

            acc[key] = true;

            return acc;
          }, {} as Record<keyof CheckboxesServicesState, boolean>),
        // dangerousGoodsQuantity: (valueAddedServices || [])
        //   .find((item) => item.serviceCode === DHLOptionalServiceCodes.dangerousGoods)
        //   ?.dangerousGoods?.[0]?.quantity || 1,
        dangerousGoodsQuantity:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.dangerousGoods, 'quantity'),
        dryIce:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.dryIce, 'quantity'),
        dryIceKg:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.dryIce, 'dryIceTotalNetWeight'),
        exceptedQuantity:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.exceptedQuantity, 'quantity'),
        limitedQuantity:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.limitedQuantity, 'quantity'),
        lithiumIon967:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.lithiumIon967, 'quantity'),
        lithiumIon966:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.lithiumIon966, 'quantity'),
        lithiumMetal969:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.lithiumMetal969, 'quantity'),
        lithiumMetal970:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.lithiumMetal970, 'quantity'),
        unCodes:
          prepareAddedServiceItem(valueAddedServices, DHLOptionalServiceCodes.exceptedQuantity, 'unCodes', []),
      },
      valueAddedServices,
      /** Customer fields: (admin has they on general tab) */
      // modeOfTransport,
      // deliveryTerms,
      // insurance,
      // insuranceValue,
      // insuranceCurrency: insuranceCurrency || 'CHF',
      // notes,
      modeOfTransport,
      consignmentDate: consignmentDate ? dayjs(consignmentDate).utc() : null,
      pickupDate: preparedPickupDate,
      pickupTime: preparedPickupTime,
      deliveryTerms,
      billingNumber,
      insurance,
      insuranceValue,
      insuranceCurrency: insuranceCurrency || 'CHF',
      emergencyPhoneNumber,
      notes,
    },
    orderType: {
      productType,
      /** If products type is 'dangerous' or temperature control selected, then assistance preset 'true'. */
      isAssistanceNeeded: ['dangerous', 'both'].includes(productType || '') || isTemperatureControl
        ? true
        : isAssistanceNeeded,
      assistanceNote,
      isTemperatureControl,
      urgent: shipper?.urgent,
      isOfferNeeded,
    },
  };

  return data;
}

export default {
  getServerData,
  getClientDate: getClientData,
};
