import React, { useEffect, useState } from 'react';
import {
  Button, Col, Divider, Dropdown, Modal, Row,
  Spin,
  Tag,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { downloadFromAnchor, getUserRequestPath } from '../../../../utils';
import { useAuth } from '../../../../store/auth';
import { ClientData, getClientData } from '../../Orders/Adapter';
import { Order } from '../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import { getOffersStatusColor } from '..';
import {
  useOfferDownload, useOfferResend, useOffersDelete, useOffersGetById,
} from '../../../../hooks/api/offers';

import styles from './index.module.scss';

interface OffersProps{
  setTableKey: (value: number) => void,
  setModalOpen: (modalOpen: boolean) => void,
  showFooter?: boolean,
  id: string,
  isModalOpen: boolean,
}

/* eslint-disable max-len */

interface HeaderTitleProps {
  status: string;
}
interface FooterProps extends HeaderTitleProps {
  orderId: string;
  offerId: string;
  offerNumber: string;
  setModalOpen: (modalOpen: boolean) => void,
  setTableKey: (value: number) => void;
}

function HeaderTitle({ status }: HeaderTitleProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
      <div className="ant-modal-title">Offer Details</div>
      <Tag color={getOffersStatusColor(status)} style={{ fontWeight: '400' }}>
        {status}
      </Tag>
    </div>
  );
}

function FooterActions({
  orderId, offerId, offerNumber, setModalOpen, setTableKey,
}: FooterProps, { status }: HeaderTitleProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { open, contextHolder } = useSimpleModal();
  const offerResend = useOfferResend(getUserRequestPath(user?.role), offerId);
  const offerDelete = useOffersDelete(getUserRequestPath(user?.role), offerId);
  const downloadOffer = useOfferDownload(getUserRequestPath(user?.role), offerId);

  useMessageError([offerDelete]);
  useMessageSuccess([offerDelete], 'Offer deleted successfully');

  useEffect(() => {
    if (!offerDelete.error && !offerDelete.loading && offerDelete.data) {
      setTableKey(Date.now());
      setModalOpen(false);
    }
  }, [
    offerDelete.error, offerDelete.loading, offerDelete.data,
  ]);

  useMessageError([downloadOffer]);
  useMessageSuccess([downloadOffer], 'Download successfully');

  return (
    <div className={styles.footerActions}>
      <div>
        <Button
          type="primary"
          style={{ marginRight: '12px' }}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/orders/${orderId}`);
          }}
        >
          View order
        </Button>
        {status === 'accepted'
          ? (
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'Resend',
                    label: 'Resend',
                    onClick: ({ domEvent }) => {
                      domEvent.preventDefault();
                      offerResend.fetch();
                    },
                  },
                  {
                    key: 'Download',
                    label: 'Download',
                    onClick: ({ domEvent }) => {
                      domEvent.preventDefault();
                      downloadOffer.fetch()
                        .then((response) => {
                          if (!response) return;

                          downloadFromAnchor(response, `offer_${offerNumber}`, 'application/pdf');
                        });
                    },
                  },
                ],
              }}
              placement="bottomRight"
              arrow
            >
              <Button
                icon={<EllipsisOutlined />}
                style={{ transform: 'translate(0px, 1.5px)' }}
              />
            </Dropdown>
          )
          : (
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'Resend',
                    label: 'Resend',
                    onClick: ({ domEvent }) => {
                      domEvent.preventDefault();
                      offerResend.fetch();
                    },
                  },
                  {
                    key: 'Download',
                    label: 'Download',
                    onClick: ({ domEvent }) => {
                      domEvent.preventDefault();
                      downloadOffer.fetch()
                        .then((response) => {
                          if (!response) return;

                          downloadFromAnchor(response, `offer_${offerNumber}`, 'application/pdf');
                        });
                    },
                  },
                  {
                    key: 'Delete',
                    label: 'Delete',
                    onClick: () => {
                      open({
                        icon: <ExclamationCircleFilled />,
                        title: 'Delete offer?',
                        content: (
                          <span>
                            {`Are you sure you want to delete offer ${offerNumber} ?`}
                          </span>
                        ),
                        cancelText: 'Cancel',
                        okText: 'Delete',
                        okButtonProps: {
                          danger: true,
                        },
                        onOk: () => {
                          offerDelete.fetch();
                        },
                      });
                    },
                    danger: true,
                  },
                ],
              }}
              placement="bottomRight"
              arrow
            >
              <Button
                icon={<EllipsisOutlined />}
                style={{ transform: 'translate(0px, 1.5px)' }}
              />
            </Dropdown>
          )}

        {contextHolder}
      </div>
    </div>
  );
}

function OffersModal({
  showFooter = false, id, isModalOpen, setTableKey, setModalOpen,
}: OffersProps) {
  const { user } = useAuth();
  const offersGetById = useOffersGetById(getUserRequestPath(user?.role), id);
  const [orderData, setOrderData] = useState<ClientData | undefined>({} as ClientData);

  useEffect(() => {
    if (id) {
      offersGetById.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (offersGetById.data) {
      setOrderData(getClientData(offersGetById.data?.order || {} as Order, 'edit'));
    }
  }, [offersGetById.data]);

  const {
    currency,
    number,
    createdAt,
    dueDate,
    offerStatus,
    services,
    roundDifference,
  } = offersGetById.data || {};
  const {
    companyName,
    email,
    address1,
    address2,
    postalCode,
    city,
    country,
  } = orderData?.company || {};
  const {
    reference,
    contactName,
    company: shipperFromCompany,
    country: shipperFromCountry,
  } = orderData?.shipper || {};
  const {
    company: shipperToCompany,
    country: shipperToCountry,
  } = orderData?.importer || {};

  const totalQuantity = orderData?.packages?.packages?.length
    ? orderData?.packages?.packages?.reduce((sum, pack) => sum + pack.quantity, 0)
    : orderData?.goods?.goods?.reduce((sum, pack) => sum + pack.quantity, 0);

  const totalVolume = orderData?.packages?.packages?.length
    ? orderData.packages.packages.reduce(
      (sum, pack) => sum + (pack.height / 100) * (pack.width / 100) * (pack.length / 100),
      0,
    ).toFixed(3)
    : orderData?.goods?.goods.reduce(
      (sum, pack) => sum + (pack.height / 100) * (pack.width / 100) * (pack.length / 100) * pack.quantity,
      0,
    );

  const totalWeight = orderData?.packages?.packages.length
    ? orderData?.packages?.packages.reduce((sum, pack) => sum + pack.weight, 0)
    : orderData?.goods?.goods?.reduce((sum, pack) => sum + (pack.gross * pack.quantity), 0);

  const totalWithVAT = Number(
    services?.reduce((total, service) => total + ((service.value * service.quantity) + ((service.tax / 100) * service.value * service.quantity)), 0),
  );

  const subtotal = Number(
    services?.reduce((total, service) => total + service.value * service.quantity, 0),
  );

  const totalVAT = Number(
    services?.reduce((total, service) => total + ((service.tax / 100) * service.value * service.quantity), 0),
  );

  const roundDifferenceValue = roundDifference || 0;

  const amountDue = totalWithVAT - roundDifferenceValue;

  return (
    <Modal
      title={<HeaderTitle status={offerStatus as string} />}
      open={isModalOpen}
      centered
      closable={!showFooter}
      maskClosable={false}
      width={1000}
      footer={false}
      onCancel={() => {
        setModalOpen(false);
      }}
    >
      <Divider />
      <div>
        <div className={styles.invoiceGrid}>
          <div className={styles.invoiceHeader}>
            {number ? (
              <h2>{`OFFER ${number}`}</h2>
            ) : null}
            <img src="/logo.png" alt="Logo" />
          </div>

          <div className={styles.invoiceInfo} style={{ marginTop: '50px' }}>
            <div className={styles.column}>
              <div>
                <strong>Created date:</strong>
                {createdAt ? (
                  <span>{dayjs(createdAt).format('D MMMM YYYY')}</span>
                ) : null}
              </div>
              <div>
                <strong>Due date:</strong>
                {dueDate ? (
                  <span>{dayjs(dueDate).format('D MMMM YYYY')}</span>
                ) : null}
              </div>
              <div>
                <strong>Shipper reference:</strong>
                {reference ? (
                  <span>{reference}</span>
                ) : null}
              </div>
            </div>
            <div className={styles.column}>
              <div>
                <strong>Contact Email:</strong>
                <span>accounting@sephyre.com</span>
              </div>
            </div>

            <div className={styles.column}>
              {companyName ? (
                <span>{companyName}</span>
              ) : null}
              {email ? (
                <span>{email}</span>
              ) : null}
              {address1 ? (
                <span>{address1}</span>
              ) : null}
              {address2 ? (
                <span>{address2}</span>
              ) : null}
              {postalCode ? (
                <span>{postalCode}</span>
              ) : null}
              {city ? (
                <span>{city}</span>
              ) : null}
              {country ? (
                <span>{country}</span>
              ) : null}
            </div>
            <div className={styles.column} />
            <div className={styles.column}>
              <strong>Shipping details</strong>
              {orderData?.shipper ? (
                <span>
                  {`Shipper contact ${contactName || ''}. From 
                  ${shipperFromCompany || ''}, 
                  ${shipperFromCountry} - ${shipperToCompany}, 
                  ${shipperToCountry}`}
                </span>
              ) : null}
            </div>
            <div className={styles.column}>
              <strong>Product</strong>
              <span>
                {totalQuantity ? `${totalQuantity}x,` : ''}
                {totalWeight ? ` ${totalWeight}kg,` : ''}
                {totalVolume ? ` ${Number(totalVolume).toFixed(4).replace(/\.?0+$/, '')}m³, ` : ''}
                {orderData?.goods?.goods ? (
                  ` ${[...new Set(orderData.goods.goods
                    .map((item) => item.name || '')
                    .filter(Boolean))].join(', ')}`
                ) : null}
                {orderData?.goods?.goods?.some((item) => item.dangerousGood?.unNumber) && (
                  ` / ${[...new Set(orderData.goods.goods
                    .map((item) => (item.dangerousGood?.unNumber ? `UN${item.dangerousGood.unNumber}` : ''))
                    .filter(Boolean))].join(', ')}`
                )}
              </span>
            </div>
          </div>
          <div className={styles.invoiceHeader}>
            <h2 style={{ margin: '20px 0' }}>
              Total to pay
              {' '}
              {amountDue.toFixed(2)}
              {' '}
              {currency}
            </h2>
          </div>
        </div>
        <div style={{ padding: '0 25px' }}>
          <Row
            gutter={24}
            justify="center"
          >
            <Col span={11} className={styles.tableCol}>
              <strong className={styles.tableRow}>
                Service
              </strong>
              <Divider className={styles.divederStyle} />
            </Col>
            <Col span={2} className={styles.tableCol} style={{ alignItems: 'center' }}>
              <strong className={styles.tableRow}>
                Qty.
              </strong>
            </Col>
            <Col span={3} className={styles.tableCol} style={{ alignItems: 'end' }}>
              <strong className={styles.tableRow}>
                Unit price
              </strong>
            </Col>
            <Col span={3} className={styles.tableCol} style={{ alignItems: 'end' }}>
              <strong className={styles.tableRow}>
                VAT
              </strong>
            </Col>
            <Col span={4} className={styles.tableCol} style={{ alignItems: 'end' }}>
              <strong className={styles.tableRow}>
                {`Total price ${currency || 'CHF'}`}
              </strong>
            </Col>
          </Row>
          {services ? (
            services.map((service, index) => {
              if (service.value === 0 && service.tax === 0) {
                return null;
              }

              return (
              // eslint-disable-next-line react/no-array-index-key
                <Row key={index} gutter={24} justify="center">
                  <Col span={11} className={styles.tableCol}>
                    <span className={styles.tableRow}>
                      {service.name}
                    </span>
                    <Divider className={styles.divederStyle} style={{ borderBlockStart: '1px solid rgba(146, 146, 146, 0.711)' }} />
                  </Col>
                  <Col span={2} className={styles.tableCol} style={{ alignItems: 'center' }}>
                    <span className={styles.tableRow}>
                      {service.quantity}
                    </span>
                  </Col>
                  <Col span={3} className={styles.tableCol} style={{ alignItems: 'end' }}>
                    <span className={styles.tableRow}>
                      {(service.value).toFixed(2)}
                    </span>
                  </Col>
                  <Col span={3} className={styles.tableCol} style={{ alignItems: 'end' }}>
                    <span className={styles.tableRow}>
                      {((service.tax / 100) * service.value * service.quantity).toFixed(2)}
                    </span>
                  </Col>
                  <Col span={4} className={styles.tableCol} style={{ alignItems: 'end' }}>
                    <span className={styles.tableRow}>
                      {Number(((service.value * service.quantity) + ((service.tax / 100) * service.value * service.quantity))).toFixed(2)}
                    </span>
                  </Col>
                </Row>
              );
            })
          ) : null}

          <Row gutter={12} justify="end">
            <Col span={8}>
              <span className={styles.tableRow}>
                Subtotal
              </span>
              <Divider className={styles.divederStyleRow} />
              <span className={styles.tableRow}>
                Total excluding VAT
              </span>
              <Divider className={styles.divederStyleRow} />
              <span className={styles.tableRow}>
                VAT
              </span>
              <Divider className={styles.divederStyleRow} />
              <span className={styles.tableRow}>
                Rounding difference
              </span>
              <Divider className={styles.divederStyleRow} />
              <strong className={styles.tableRow}>
                {`Amount due ${currency || 'CHF'}`}
              </strong>
            </Col>
            <Col span={4} className={styles.tableCol} style={{ alignItems: 'end', paddingRight: '24px' }}>
              <span className={styles.tableRow}>
                {subtotal.toFixed(2)}
              </span>
              <span className={styles.tableRow}>
                {totalWithVAT.toFixed(2)}
              </span>
              <span className={styles.tableRow}>
                {totalVAT.toFixed(2)}
              </span>
              <span className={styles.tableRow}>
                {roundDifferenceValue.toFixed(2)}
              </span>
              <strong className={styles.tableRow}>
                {amountDue.toFixed(2)}
              </strong>
            </Col>
          </Row>
          <div className={styles.invoiceInfo} style={{ marginTop: '48px' }}>
            <div className={styles.column}>
              <span style={{ marginTop: '12px' }}>We remain at your disposal for any further questions</span>
              <span>Kind regards</span>
            </div>
          </div>
          <div className={styles.invoiceFooter}>
            <div className={styles.footerInfo}>
              <strong>Sephyre GmbH</strong>
              <span>CH-4450 Sissach, Switzerland  </span>
              <strong>Bank:</strong>
              <span>Raiffeisen</span>
              <strong>Account owner:</strong>
              <span>Sephyre GmbH</span>
              <strong>BIC:</strong>
              <span>RAIFCH22XXX</span>
            </div>
            <div className={styles.footerInfo}>
              <strong>IBAN:</strong>
              <span>CH16 8080 8006 3843 9480 1</span>
              <strong>VAT identification number:</strong>
              <span>CHE-232.506.635 MWST</span>
              <strong>Website:</strong>
              <span>sephyre.com</span>
            </div>
            <Divider />
            <div>
              <FooterActions
                orderId={offersGetById.data?.order.id as string}
                offerId={id}
                offerNumber={offersGetById.data?.number as string}
                status={offersGetById.data?.offerStatus as string}
                setModalOpen={setModalOpen}
                setTableKey={setTableKey}
              />
            </div>
          </div>
        </div>
      </div>
      {offersGetById.loading ? (
        <div className="spin-loading">
          <Spin />
        </div>
      ) : null}
    </Modal>
  );
}

export default OffersModal;
